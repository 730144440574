/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable global-require */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import './MapPage.css';
import {
  YMaps, Map, Placemark, Clusterer
} from '@pbe/react-yandex-maps';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import CarService from '../../services/CarService';
import {
  FilterResponse, CarIdResponse, CarsGeoPosition, ControlOptions
} from '../../models/response/CarResponse';
import { BallonPortal } from '../../components/BallonPortal/BallonPortal';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import {
  arrowNext, carCharge50Hor, carChargeLess20Hor, carChargeMore50Hor,
  carOfflineHor,
  stationPin,
  vectorLeft,
  vectorRight
} from '../../Icons/Icons';
import Select from '../../components/Select/Select';
import carsPng from '../../Icons/cars-icons';
import stationsPng from '../../Icons/stations-icons';
import MySwitch from '../../components/Switch/Switch';
import { ChargePointResponse, StationsResponse } from '../../models/response/ChargeResponse';
import ChargeService from '../../services/ChargeService';
import { Context } from '../..';

const MapPage = () => {
  const [cars, setCars] = useState<CarsGeoPosition | null>(null);
  const [stations, setStations] = useState<StationsResponse | null>(null);
  const [activePortal, setActivePortal] = useState(false);
  const [activeCarId, setActiveCarId] = useState<string>('');
  const [activeStationId, setActiveStationId] = useState<string>('');
  const [carInfo, setCarInfo] = useState<CarIdResponse | null>(null);
  const [stationInfo, setStationInfo] = useState<ChargePointResponse | null>(null);
  const [carOptions, setCarOptions] = useState<ControlOptions | null>(null);
  const [carFiltersData, setCarFiltersData] = useState<FilterResponse>([]);
  const [stationsFiltersData, setStationsFiltersData] = useState<FilterResponse>([]);
  const [loading, setLoading] = useState(false);
  const carsRef = useRef<CarsGeoPosition | null>(null);

  const [showCars, setShowCars] = useState(true);
  const [showStations, setShowStations] = useState(false);
  const [firstTimeToggleStation, setFirstTimeToggleStation] = useState(false);

  const [carSelectedOptions, setCarSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [carTextValues, setCarTextValues] = useState<{ [key: string]: string }>({});
  const [carBooleanValues, setCarBooleanValues] = useState<{ [key: string]: boolean | undefined}>({});

  const [stationSelectedOptions, setStationSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [stationTextValues, setStationTextValues] = useState<{ [key: string]: string }>({});
  const [stationBooleanValues, setStationBooleanValues] = useState<{ [key: string]: boolean | undefined}>({});

  const [carsFilters, setCarsFilters] = useState<any>([]);
  const [stationsFilters, setStationsFilters] = useState<any>([]);
  const [showStationsFilters, setShowStationsFilters] = useState(true);
  const [showCarsFilters, setShowCarsFilters] = useState(true);

  let carImage;
  if (carInfo?.carModel.name.includes('i-JOY')) {
    carImage = carsPng.iJoy;
  } else if (carInfo?.carModel.name.includes('i-SKY')) {
    carImage = carsPng.iSky;
  } else if (carInfo?.carModel.name.includes('i-JET')) {
    carImage = carsPng.iJet;
  } else if (carInfo?.carModel.name.includes('i-VAN')) {
    carImage = carsPng.iVan;
  } else if (carInfo?.carModel.name.includes('i-SPACE')) {
    carImage = carsPng.iSpace;
  } else if (carInfo?.carModel.name.includes('i-PRO')) {
    carImage = carsPng.iPro;
  } else if (carInfo?.carModel.name.includes('DF580') || carInfo?.carModel.name.includes('DF500')) {
    carImage = carsPng.DF580;
  } else if (carInfo?.carModel.name.includes('DF600')) {
    carImage = carsPng.DF600;
  } else if (carInfo?.carModel.name.includes('IX5')) {
    carImage = carsPng.IX5;
  } else if (carInfo?.carModel.name.includes('IX7')) {
    carImage = carsPng.IX7;
  } else if (carInfo?.carModel.name.includes('ShineMax') || carInfo?.carModel.name.includes('SHINE_MAX')) {
    carImage = carsPng.ShineMax;
  }

  // Фильтры машины

  const handleCarTextChange = debounce((text: string, key: string) => {
    setCarTextValues((prevTextValues) => ({
      ...prevTextValues,
      [key]: text,
    }));
  }, 2000);

  const handleCarOptionSelect = debounce((selected: string[], filterKey: string) => {
    setCarSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [filterKey]: selected,
    }));
  }, 2000);

  const handleCarBooleanChange = debounce((value: boolean, key: string) => {
    if (carBooleanValues[key] !== value) {
      setCarBooleanValues((prevBooleanValues) => ({
        ...prevBooleanValues,
        [key]: value,
      }));
    }
  }, 2000);

  const handleCarClearClick = debounce((filterKey: string) => {
    setCarTextValues((prevValues) => ({ ...prevValues, [filterKey]: '' }));
  }, 2000);

  const handleCarBooleanClear = debounce((filterKey: string) => {
    if (carBooleanValues[filterKey] !== undefined) {
      setCarBooleanValues((prevValues) => ({
        ...prevValues,
        [filterKey]: undefined,
      }));
    }
  }, 2000);

  const handleStationTextChange = debounce((text: string, key: string) => {
    setCarTextValues((prevTextValues) => ({
      ...prevTextValues,
      [key]: text,
    }));
  }, 2000);

  // Фильтры станций

  const handleStationOptionSelect = debounce((selected: string[], filterKey: string) => {
    setStationSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [filterKey]: selected,
    }));
  }, 2000);

  const handleStationBooleanChange = debounce((value: boolean, key: string) => {
    if (stationBooleanValues[key] !== value) {
      setStationBooleanValues((prevBooleanValues) => ({
        ...prevBooleanValues,
        [key]: value,
      }));
    }
  }, 2000);

  const handleStationClearClick = debounce((filterKey: string) => {
    setStationTextValues((prevValues) => ({ ...prevValues, [filterKey]: '' }));
  }, 2000);

  const handleStationBooleanClear = debounce((filterKey: string) => {
    if (stationBooleanValues[filterKey] !== undefined) {
      setStationBooleanValues((prevValues) => ({
        ...prevValues,
        [filterKey]: undefined,
      }));
    }
  }, 2000);

  // Машины

  const fetchCarsFilters = async () => {
    try {
      const response = await CarService.getFilters();
      if (response && response.data) {
        setCarFiltersData(response.data);
      } else {
        // console.error('Ответ не содержит данных.');
      }
    } catch (error) {
      // console.error('Ошибка при загрузке фильтров:', error);
    }
  };

  const filteredCarsData = async () => {
    setCarsFilters([]);
    for (const filter of carFiltersData) {
      if (
        (filter.type === 'MULTISELECT' && carSelectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'TEXT' && carTextValues[filter.key]) ||
          (filter.type === 'BOOLEAN' && carBooleanValues[filter.key] !== undefined)
      ) {
        let values;
        if (filter.type === 'MULTISELECT') {
          values = carSelectedOptions[filter.key];
        } else if (filter.type === 'TEXT') {
          values = [carTextValues[filter.key]];
        } else if (filter.type === 'BOOLEAN') {
          values = [carBooleanValues[filter.key]];
        }
        carsFilters.push({
          key: filter.key,
          values,
        });
      }
    }

    try {
      const response = await CarService.geoPositionCars(carsFilters);
      carsRef.current = response.data;
      setCars(response.data);
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    }
  };

  const fetchCarInfo = async (carId: string) => {
    setLoading(true);
    try {
      const car = await CarService.getCar(carId);
      const carOptionsRequest = await CarService.getManagementOptions(carId);
      setCarInfo(car.data);
      setCarOptions(carOptionsRequest.data);
    } catch (error) {
      // console.error('Ошибка при получении информации о машине:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderCarsPlacemarks = () => {
    if (!cars) return null;

    return cars.rows.map((car) => (
      <Placemark
        key={car._id}
        geometry={[car.lat, car.lon]}
        options={{
          iconLayout: 'default#image',
          iconImageHref: car.battery === undefined
            ? carsPng.carOffline
            : car.battery > 50
              ? carsPng.carChargeMore50
              : car.battery >= 20
                ? carsPng.carCharge50
                : carsPng.carChargeLess20,
          iconImageSize: [21, 36],
          iconImageOffset: [-10, -18],
          hideIconOnBalloonOpen: false,
        }}
        properties={{
          iconContent: `<div id=${car._id} class="car-ballon"></div>`,
          balloonContent: `<div id=${car._id} class="car-ballon"></div>`,
        }}
        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
        onBalloonOpen={() => {
          document.getElementById(`balloon_${car._id}`);
        }}
        onClick={() => {
          setActiveCarId(car._id);
          setTimeout(() => { setActivePortal(true); }, 0);
          fetchCarInfo(car._id);
        }}
      />
    ));
  };

  // Станции

  const fetchStationsFilters = async () => {
    try {
      const response = await ChargeService.getFilters();
      if (response && response.data) {
        setStationsFiltersData(response.data);
      } else {
        // console.error('Ответ не содержит данных.');
      }
    } catch (error) {
      // console.error('Ошибка при загрузке фильтров:', error);
    }
  };

  const filteredStationsData = async () => {
    setStationsFilters([]);
    for (const filter of stationsFiltersData) {
      if (
        (filter.type === 'MULTISELECT' && stationSelectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'TEXT' && stationTextValues[filter.key]) ||
          (filter.type === 'BOOLEAN' && stationBooleanValues[filter.key] !== undefined)
      ) {
        let values;
        if (filter.type === 'MULTISELECT') {
          values = stationSelectedOptions[filter.key];
        } else if (filter.type === 'TEXT') {
          values = [stationTextValues[filter.key]];
        } else if (filter.type === 'BOOLEAN') {
          values = [stationBooleanValues[filter.key]];
        }
        stationsFilters.push({
          key: filter.key,
          values,
        });
      }
    }

    try {
      const response = await ChargeService.getStations(stationsFilters, 0, 0, true);
      carsRef.current = response.data;
      setStations(response.data);
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    }
  };

  const fetchStationInfo = async (stationId: string) => {
    setLoading(true);
    try {
      const station = await ChargeService.getChargePoint(stationId);
      setStationInfo(station.data);
    } catch (error) {
      // console.error('Ошибка при получении информации о машине:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderStationPlacemarks = () => {
    if (!stations) return null;

    return stations.rows.map((station) => (
      <Placemark
        key={station._id}
        geometry={[station.location.latitude, station.location.longitude]}
        options={{
          iconLayout: 'default#image',
          iconImageHref: stationsPng.stationDefaultPin,
          iconImageSize: [35, 35],
          iconImageOffset: [-15, -5],
          hideIconOnBalloonOpen: false,
        }}
        properties={{
          iconContent: `<div id=${station._id} class="station-ballon"></div>`,
          balloonContent: `<div id=${station._id} class="station-ballon"></div>`,
        }}
        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
        onBalloonOpen={() => {
          document.getElementById(`balloon_${station._id}`);
        }}
        onClick={() => {
          setActiveStationId(station._id);
          setTimeout(() => { setActivePortal(true); }, 0);
          fetchStationInfo(station._id);
        }}
      />
    ));
  };

  function closeCurrentBalloon() {
    const close = document.querySelector('ymaps[class$="-balloon__close-button"]') as HTMLElement;
    if (close != null) {
      close.click();
    }
  }

  useEffect(() => {
    fetchCarsFilters();
    fetchStationsFilters();
  }, []);

  useEffect(() => {
    filteredCarsData();
  }, [carSelectedOptions, carTextValues, carBooleanValues]);

  useEffect(() => {
    if (firstTimeToggleStation) {
      filteredStationsData();
    }
  }, [stationSelectedOptions, stationTextValues, stationBooleanValues, firstTimeToggleStation]);

  useEffect(() => {
    if (carsRef.current !== null) {
      setCars(carsRef.current);
    }
  }, [carsRef]);

  return (
  <div className='maps'>
    <YMaps>
      <Map
        onClick={closeCurrentBalloon}
        width={'100%'}
        height={'95vh'}
          defaultState={{
            center: [55.751574, 37.573856],
            zoom: 9,
          }}
        modules={['geoObject.addon.balloon', 'geoObject.addon.hint', 'layout.ImageWithContent']}
      >
      {showCars &&
      <Clusterer
        options={{
          groupByCoordinates: false,
        }}
      >
        {renderCarsPlacemarks()}
        </Clusterer>}
        {showStations &&
        <Clusterer
        clusterIconColor={'--MySecondaryBlue'}
        options={{
          groupByCoordinates: false,
          preset: 'islands#violetClusterIcons',
        }}
        >
        {renderStationPlacemarks()}
        </Clusterer>}
        {activePortal && activeCarId && (
        <BallonPortal elementId={activeCarId} style={{ zIndex: 0 }}>
          {!loading ?
            <div style={{ zIndex: '100' }}>
              <div className='carPin-title'>
                <div className='carPin-carModel'>Evolute {carInfo?.carModel.name}</div>
              </div>
              <div className='carPin-img-container'>
                <img className='carPin-img' src={carImage} alt={carInfo?.carModel.name} />
              </div>
              <div className='carPin-options-list'>
                  <div className='carPin-option'>
                    <span className='carPin-option-title'>Заряд батареи</span>
                    <span className='carPin-option-value'>{carOptions?.sensors.sensorsData.batteryPercentage || '-'}%</span>
                  </div>
                  <div className='carPin-separator'></div>
                  <div className='carPin-option'>
                    <span className='carPin-option-title'>Темп. батареи</span>
                    <span className='carPin-option-value'>{carOptions?.sensors.sensorsData.batteryTemp || '-'}°С</span>
                  </div>
                  <div className='carPin-separator'></div>
                  <div className='carPin-option'>
                    <span className='carPin-option-title'>АКБ 12V</span>
                    <span className='carPin-option-value'>
                      {Number.isNaN(Number(carOptions?.sensors.sensorsData['12VBatteryVoltage']))
                        ? '-'
                        : `${Number(carOptions?.sensors.sensorsData['12VBatteryVoltage']).toFixed(2)}`}V
                    </span>
                  </div>
                  <div className='carPin-separator'></div>
                  <div className='carPin-option'>
                    <span className='carPin-option-title'>Гос. номер</span>
                    <span className='carPin-option-value'>{carInfo?.licensePlate || '-'}</span>
                  </div>
                  <div className='carPin-separator'></div>
                  <div className='carPin-option'>
                    <span className='carPin-option-title'>VIN</span>
                    <span className='carPin-option-value'>{carInfo?.vin || '-'}</span>
                  </div>
                </div>
                <div className='carPin-button'>
                  <a href={`/fleet/car/${activeCarId}`}>
                    <LargeButtonWithIcon padding='8px' fontSize='12px' fontWeight='500px' iconMarginLeft='8px' RightIcon={arrowNext} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} height='32px' text={'Перейти к электромобилю'} borderRadius='10px' />
                  </a>
                </div>
            </div>
            : <p>Загрузка машины...</p>}
        </BallonPortal>
        )}
        {activePortal && activeStationId && (
          <BallonPortal elementId={activeStationId} style={{ zIndex: 0, height: 'auto' }}>
            {!loading ?
              <div style={{ zIndex: '100', height: 'auto' }}>
                <div className='carPin-title'>
                  <div className='carPin-carModel'>{stationInfo?.name}</div>
                  <div>
                    <span className='carPin-station-type'>Публичная</span>
                  </div>
                </div>
                <div className='carPin-options-list'>
                  <div className='carPin-option'>
                    <span className='carPin-option-title'>Локация</span>
                    <span className='carPin-option-value'>{stationInfo?.address}</span>
                  </div>
                </div>
                <div className='carPin-button'>
                  <a href={`/charging/${activeStationId}`}>
                    <LargeButtonWithIcon padding='8px' fontWeight='500px' iconMarginLeft='8px' RightIcon={arrowNext} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} height='32px' text={'Перейти к зарядной станции'} borderRadius='10px' fontSize='12px'/>
                  </a>
                </div>
              </div>
              : <p>Загрузка станции...</p>}
          </BallonPortal>
        )}
      </Map>
    </YMaps>
    <div className='maps-legend'>
      <div className='maps-legend-carPins-list-container'>
        <div className='carPin-charge-container'>
          <span className='carPin-charge-img'>{carChargeMore50Hor}</span>
          <span className='carPin-charge-title'>Авто с зарядом больше 50%</span>
        </div>
        <div className='carPin-charge-container'>
          <span className='carPin-charge-img'>{carCharge50Hor}</span>
          <span className='carPin-charge-title'>Авто с зарядом 20%–50%</span>
        </div>
        <div className='carPin-charge-container'>
          <span className='carPin-charge-img'>{carChargeLess20Hor}</span>
          <span className='carPin-charge-title'>Авто с зарядом меньше 20%</span>
        </div>
        <div className='carPin-charge-container'>
          <span className='carPin-charge-img'>{carOfflineHor}</span>
          <span className='carPin-charge-title'>Авто не в сети</span>
        </div>
        <div className='chargePin-charge-container'>
          <span className='stationPin-charge-img'>{stationPin}</span>
          <span className='carPin-charge-title'>Зарядная станция</span>
        </div>
      </div>
    </div>
    <div className='maps-filters'>
    <div className='maps-filters-container'>
      <div className='maps-filters-switch-container'>
        <span>Зарядные станции</span>
        <MySwitch
          checked={showStations}
          onChange={() => {
            setFirstTimeToggleStation(true);
            setShowStations((prev) => !prev);
          }}/>
      </div>
      {showStationsFilters && stationsFiltersData && stationsFiltersData.length > 0 && (
        <>
          {stationsFiltersData.map((filter, index) => (
          <div key={index}>
          {filter.type === 'MULTISELECT' && (
            <Select
              label={filter.name || filter.key}
              options={filter.options}
              selectedOptions={stationSelectedOptions[filter.key] || []}
              onOptionSelect={(selected) => handleStationOptionSelect(selected, filter.key)}
              type={filter.type}
              filterKey={filter.key}
            />
          )}
          {filter.type === 'TEXT' && (
            <Select
              label={filter.name || filter.key}
              options={[]}
              selectedOptions={[]}
              onClearClick={() => handleStationClearClick(filter.key)}
              onOptionSelect={(value: string) => handleStationTextChange(value, filter.key)}
              type={filter.type}
              filterKey={filter.key}
            />
          )}
          {filter.type === 'BOOLEAN' && (
            <Select
              label={filter.name || filter.key}
              options={[]}
              onClearClick={() => handleStationBooleanClear(filter.key)}
              onOptionSelect={(value: boolean) => (stationBooleanValues[filter.key] && stationBooleanValues[filter.key] === true ? handleStationBooleanClear(filter.key) : handleStationBooleanChange(value, filter.key))}
              type={filter.type}
              filterKey={filter.key}
            />
          )}
        </div>
          ))}
        </>
      )}
      <LargeButtonWithIcon
        height='38px'
        padding='9.5px'
        text={''}
        color={''}
        backgroundColor={'--MyGrey2'}
        RightIcon={showStationsFilters ? vectorRight : vectorLeft}
        onClick={() => setShowStationsFilters((prev) => !prev)}
      />
    </div>
      <div className='maps-filters-container'>
        <div className='maps-filters-switch-container'>
          <span>Автомобили</span>
          <MySwitch checked={showCars} onChange={() => setShowCars((prev) => !prev)}/>
        </div>
        {showCarsFilters && carFiltersData && carFiltersData.length > 0 && (
          <>
            {carFiltersData.map((filter, index) => (
            <div key={index}>
            {filter.type === 'MULTISELECT' && (
              <Select
                label={filter.name || filter.key}
                options={filter.options}
                selectedOptions={carSelectedOptions[filter.key] || []}
                onOptionSelect={(selected) => handleCarOptionSelect(selected, filter.key)}
                type={filter.type}
                filterKey={filter.key}
              />
            )}
            {filter.type === 'TEXT' && (
              <Select
                label={filter.name || filter.key}
                options={[]}
                selectedOptions={[]}
                onClearClick={() => handleCarClearClick(filter.key)}
                onOptionSelect={(value: string) => handleCarTextChange(value, filter.key)}
                type={filter.type}
                filterKey={filter.key}
              />
            )}
            {filter.type === 'BOOLEAN' && (
              <Select
                label={filter.name || filter.key}
                options={[]}
                onClearClick={() => handleCarBooleanClear(filter.key)}
                onOptionSelect={(value: boolean) => handleCarBooleanChange(value, filter.key)}
                type={filter.type}
                filterKey={filter.key}
              />
            )}
          </div>
            ))}
          </>
        )}
        <LargeButtonWithIcon
          height='38px'
          padding='9.5px'
          text={''}
          color={''}
          backgroundColor={'--MyGrey2'}
          RightIcon={showCarsFilters ? vectorRight : vectorLeft}
          onClick={() => setShowCarsFilters((prev) => !prev)}
        />
      </div>
    </div>
    <div className='mapPage-title'>
      <h2>Карта</h2>
    </div>
  </div>
  );
};

export default observer(MapPage);
