// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosResponse } from 'axios';
import { API_STATIONS } from '../http/MyApi';
import { createAxiosInstance } from '../http/http';
import {
  ChargerCreate,
  ChargeFilterResponse, ChargePointResponse, ResetResponse, Station, StationLogsResponse
} from '../models/response/ChargeResponse';
import { SessionAnalyticsData } from '../models/response/AnalyticsResponse';

const $api = createAxiosInstance(API_STATIONS);

export default class ChargeService {
  // Станции

  static async getStations(filters: object[], limit: number, offset: number, short: boolean = false)
  : Promise<AxiosResponse> {
    return $api.post('/charger/search', {
      filters, limit, offset, short
    });
  }

  static async getChargePoint(chargePointId: string): Promise<AxiosResponse<ChargePointResponse>> {
    return $api.get(`/charge-point/v2/${chargePointId}`);
  }

  static async getChargePointV2(chargePointId: string): Promise<AxiosResponse<Station>> {
    return $api.get(`/charger/${chargePointId}`);
  }

  static async getFilters(): Promise<AxiosResponse<ChargeFilterResponse>> {
    return $api.get('/charger/filters');
  }

  static async chargeReportXLSX(filters: object[], limit: number, offset: number): Promise<AxiosResponse> {
    return $api.post('/charger/report', { filters, limit, offset });
  }

  static async addStation(station: ChargerCreate): Promise<AxiosResponse> {
    return $api.post('/charger/create', station);
  }

  static async editStation(chargerId: string, station?: ChargerCreate): Promise<AxiosResponse> {
    return $api.put(`/charger/update/${chargerId}`, station);
  }

  static async hideStation(chargerId: string): Promise<AxiosResponse<ChargeFilterResponse>> {
    return $api.get(`/charger/${chargerId}/hide`);
  }

  // Логи

  static async getStationLogs(filters: object[], limit: number, offset: number, pageToken?: string)
  : Promise<AxiosResponse<StationLogsResponse>> {
    return $api.post('/charger/logs', {
      filters, limit, offset, pageToken
    });
  }

  // Ресет

  static async stationReset(chargerSerialNumber: string, restartType: string): Promise<AxiosResponse<ResetResponse>> {
    return $api.get(`/charger/${chargerSerialNumber}/restart/${restartType}`);
  }

  // Редактирование тарифов в коннекторе

  static async updateRateToConnector(data: string[], stationId: string, connectorId: string): Promise<AxiosResponse> {
    return $api.put(`/charger/${stationId}/${connectorId}/rate`, data);
  }

  // Тарифы

  static async getRates(filters: object[]): Promise<AxiosResponse> {
    return $api.post('/rate/search', { filters, limit: 100, offset: 0 });
  }

  static async getOwnerRates(chargeStationId: string): Promise<AxiosResponse> {
    return $api.get(`/rate/getOwnerStationRates/${chargeStationId}`);
  }

  static async createRate(data: any): Promise<AxiosResponse> {
    return $api.post('/rate/createRate', data);
  }

  static async updateRate(data: any, rateId: string): Promise<AxiosResponse> {
    return $api.put(`/rate/updateRate/${rateId}`, data);
  }

  static async deleteRate(rateId: string): Promise<AxiosResponse> {
    return $api.delete(`/rate/deleteRate/${rateId}`);
  }

  // Сессии

  static async sessionsSearch(filters: object[], limit: number, offset: number): Promise<AxiosResponse> {
    return $api.post('/session/search', { filters, limit, offset });
  }

  static async getSessionsSearchFilters(): Promise<AxiosResponse> {
    return $api.get('/session/filters');
  }

  static async getSessionById(sessionId: string): Promise<AxiosResponse> {
    return $api.get(`/session/${sessionId}`);
  }

  static async sessionsReportXLSX(filters: object[], limit: number, offset: number): Promise<AxiosResponse> {
    return $api.post('session/receiveReport', { filters, limit, offset });
  }

  static async getSessionsFilters(): Promise<AxiosResponse<ChargeFilterResponse>> {
    return $api.get('/rate/filters');
  }

  // Аналитика

  static async getAnalyticsFilters(): Promise<AxiosResponse<ChargeFilterResponse>> {
    return $api.get('/analytics/filters');
  }

  static async analyticsSearch(filters: object[]): Promise<AxiosResponse> {
    return $api.post('/analytics/search', { filters });
  }

  static async getAnalyticsSession(sessionId: string): Promise<AxiosResponse<SessionAnalyticsData[]>> {
    return $api.get(`/analytics/session/${sessionId}`);
  }
}
