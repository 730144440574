/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
import React, {
  useCallback,
  useContext,
  useEffect, useRef, useState
} from 'react';
import { observer } from 'mobx-react-lite';
import './ChargeSessionsPage.css';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'antd';
import isEqual from 'lodash/isEqual';
import dayjs, { Dayjs } from 'dayjs';
import * as XLSX from 'xlsx';
import parsePhoneNumber from 'libphonenumber-js';
import MoonLoader from 'react-spinners/MoonLoader';
import Select from '../../components/Select/Select';
import MyTable from '../../components/Table/MyTable';
import { FilterResponse } from '../../models/response/CarResponse';
import { Context } from '../..';
import ChargeService from '../../services/ChargeService';
import MediumButton from '../../components/buttons/MediumButton';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import Spinner from '../../components/Spinner/Spinner';

interface SessionData {
  headers: any[];
  rows: any[];
  total?: number
}

const ChargeSessionsPage: React.FC = () => {
  const { RangePicker } = DatePicker;
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [textValues, setTextValues] = useState<{ [key: string]: string }>({});
  const [booleanValues, setBooleanValues] = useState<{ [key: string]: boolean | undefined}>({});
  const [selectedDates, setSelectedDates] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [prevSelectedDates, setPrevSelectedDates] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [rangeValues, setRangeValues] = useState<{ [key: string]: any[] }>({});
  const [rangeValuesForWipe, setRangeValuesForWipe] = useState<{ [key: string]: any[] }>({});
  const [isRangeValuesChange, setIsRangeValuesChange] = useState(false);
  const [shouldWipeFilters, setShouldWipeFilters] = useState<boolean>(false);

  const [sessionsData, setSessionsData] = useState<SessionData | null>(null);
  const [filteredSessionsData, setFilteredSessionsData] = useState<SessionData | null>(null);
  const [carFiltersResponseData, setCarFiltersResponseData] = useState<FilterResponse>([]);
  const [prevFilters, setPrevFilters] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [spinnerFiltersLoading, setSpinnerFiltersLoading] = useState(true);
  const [sessionsReportLoading, setReportLoadingLoading] = useState(false);
  const [stationReportLoading, setStationReportLoading] = useState(false);

  const [offset, setOffset] = useState(0);
  const [filteredOffset, setFilteredOffset] = useState(0);

  const navigate = useNavigate();
  const { store } = useContext(Context);
  const prevIsRangeValuesChangeRef = useRef(isRangeValuesChange);

  const handleTextChange = debounce((text: string, key: string) => {
    const phoneNumber = text.replace(/\D/g, '');
    setTextValues((prevTextValues) => ({
      ...prevTextValues,
      [key]: phoneNumber,
    }));
  }, 1500);

  const handleOptionSelect = debounce((selected: string[], filterKey: string) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [filterKey]: selected,
    }));
  }, 500);

  const handleBooleanChange = debounce((value: boolean, key: string) => {
    if (booleanValues[key] !== value) {
      setBooleanValues((prevBooleanValues) => ({
        ...prevBooleanValues,
        [key]: value,
      }));
    }
  }, 500);

  const handleClearClick = debounce((filterKey: string) => {
    setTextValues((prevValues) => ({ ...prevValues, [filterKey]: '' }));
  }, 1000);

  const handleBooleanClear = debounce((filterKey: string) => {
    setLoading(true);
    if (booleanValues[filterKey] !== undefined) {
      setBooleanValues((prevValues) => ({
        ...prevValues,
        [filterKey]: undefined,
      }));
    }
    setLoading(false);
  }, 500);

  const handleRangeChange = debounce((key: any, newValues: any) => {
    setRangeValues((prevValues) => ({
      ...prevValues,
      [key]: newValues,
    }));
  }, 500);

  const handleDateChange = (dates: any) => {
    if ((dates && dates !== null)) {
      setSelectedDates(dates);
    } else {
      setSelectedDates(null);
    }
  };

  const handleAcceptRange = (key: string, values: any) => {
    setRangeValues((prevValues) => ({
      ...prevValues,
      [key]: values,
    }));
    setRangeValuesForWipe((prevValues) => ({
      ...prevValues,
      [key]: values,
    }));
    setIsRangeValuesChange((prev) => !prev);
  };

  // Фильтры

  const fetchFilters = async () => {
    try {
      const response = await ChargeService.getSessionsSearchFilters();
      if (response && response.status === 200) {
        setCarFiltersResponseData(response.data);
      }
    } catch (error) {
      // console.error('Ошибка при загрузке фильтров:', error);
    } finally {
      setSpinnerFiltersLoading(false);
    }
  };

  const filteredData = async () => {
    try {
      const newFilters: any = [];
      const uniqueFilters = new Set();

      for (const filter of carFiltersResponseData) {
        if (
          (filter.type === 'MULTISELECT' && selectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'TEXT' && textValues[filter.key]) ||
          (filter.type === 'BOOLEAN' && booleanValues[filter.key] !== undefined) ||
          (filter.type === 'RANGE' && rangeValues[filter.key]?.length > 0)
        ) {
          const filterKey = filter.key;

          if (!uniqueFilters.has(filterKey)) {
            uniqueFilters.add(filterKey);

            let values;
            if (filter.type === 'MULTISELECT') {
              values = selectedOptions[filter.key];
            } else if (filter.type === 'TEXT') {
              if (filter.key === 'CUSTOMER') {
                const phoneNumber = textValues[filter.key].startsWith('8') ? textValues[filter.key].replace(/^8/, '') : textValues[filter.key];
                values = phoneNumber;
              } else {
                values = [textValues[filter.key]];
              }
            } else if (filter.type === 'BOOLEAN') {
              values = [booleanValues[filter.key]];
            } else if (filter.type === 'RANGE') {
              values = rangeValues[filterKey];
            }

            newFilters.push({
              key: filter.key,
              values,
            });
          }
        }
      }

      const datesChanged = !isEqual(selectedDates, prevSelectedDates);
      const filtersChanged = !isEqual(prevFilters, newFilters) || datesChanged;

      if (filtersChanged) {
        setFilteredSessionsData(null);
        setFilteredOffset(0);
        setPrevSelectedDates(selectedDates);
      }

      if (selectedDates && selectedDates.length > 0) {
        newFilters.push(
          {
            key: 'RANGE_DATE',
            values: [
              `${selectedDates[0]?.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
                .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`,
              `${selectedDates[1]?.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999)
                .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`
            ],
          }
        );
      }
      store.setSessionsFilters(newFilters);
      if (newFilters.length > 0 || (selectedDates && selectedDates.length > 0)) {
        if (filtersChanged) {
          setSpinnerLoading(true);
        } else {
          setLoading(true);
        }
        const responseFilter = await ChargeService.sessionsSearch(newFilters, 50, filtersChanged ? 0 : filteredOffset);
        if (responseFilter.status !== 401) {
          setOffset(filtersChanged ? 0 : offset);
          setSessionsData(null);
          setFilteredSessionsData((prevData) => ({
            headers: responseFilter?.data.headers,
            rows: [...(filtersChanged ? [] : prevData?.rows || []), ...responseFilter.data.rows],
            total: responseFilter?.data.total,
          }));
          setFilteredOffset((prev) => prev + 50);
        }
      } else {
        if (offset < 50) {
          setSpinnerLoading(true);
        } else {
          setLoading(true);
        }
        const response = await ChargeService.sessionsSearch([], 50, offset);
        if (response.status !== 401) {
          setFilteredOffset(0);
          setFilteredSessionsData(null);
          setSessionsData((prevData) => ({
            headers: response?.data.headers,
            rows: [...(prevData?.rows || []), ...response.data.rows],
            total: response?.data.total,
          }));
          setOffset((prev) => prev + 50);
        }
      }
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    } finally {
      // setIsRangeValuesChange(false);
      setSpinnerLoading(false);
      setLoading(false);
    }
  };

  const handleReset = () => {
    try {
      setSpinnerLoading(true);
      store.setShouldWipeFilters(true);
      setShouldWipeFilters(true);
      setTextValues({});
      setSelectedOptions({});
      setBooleanValues({});
      setRangeValues({});
      handleDateChange(null);
      setShouldWipeFilters(false);
    } finally {
      setSpinnerLoading(false);
    }
  };

  // Отчеты сессий XLSX

  const handleSessionsXLSX = async () => {
    setReportLoadingLoading(true);
    try {
      const newFilters: any = [];
      const uniqueFilters = new Set();

      for (const filter of carFiltersResponseData) {
        if (
          (filter.type === 'MULTISELECT' && selectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'TEXT' && textValues[filter.key]) ||
          (filter.type === 'BOOLEAN' && booleanValues[filter.key] !== undefined) ||
          (filter.type === 'RANGE' && rangeValues[filter.key]?.length > 0)
        ) {
          const filterKey = filter.key;

          if (!uniqueFilters.has(filterKey)) {
            uniqueFilters.add(filterKey);

            let values;
            if (filter.type === 'MULTISELECT') {
              values = selectedOptions[filter.key];
            } else if (filter.type === 'TEXT') {
              values = [textValues[filter.key]];
            } else if (filter.type === 'BOOLEAN') {
              values = [booleanValues[filter.key]];
            } else if (filter.type === 'RANGE') {
              values = rangeValues[filterKey];
            }

            newFilters.push({
              key: filter.key,
              values,
            });
          }
        }
      }

      const datesChanged = !isEqual(selectedDates, prevSelectedDates);

      const filtersChanged = !isEqual(prevFilters, newFilters) || datesChanged;

      if (filtersChanged) {
        setFilteredSessionsData(null);
        setFilteredOffset(0);
        setPrevSelectedDates(selectedDates);
      }

      if (selectedDates && selectedDates.length > 0) {
        newFilters.push(
          {
            key: 'RANGE_DATE',
            values: [
              selectedDates[0]?.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
                .toISOString(),
              selectedDates[1]?.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999)
                .toISOString()
            ],
          }
        );
      }
      store.setCarFilters(newFilters);
      if (newFilters.length > 0) {
        const responseFilter = await ChargeService.sessionsReportXLSX(newFilters, 0, 0);
        if (responseFilter.status !== 401) {
          const data = new Uint8Array(responseFilter.data.data).buffer;
          const workbook = XLSX.read(data, { type: 'array' });
          XLSX.writeFile(workbook, 'SessionsReports.xlsx');
        }
      } else {
        const response = await ChargeService.sessionsReportXLSX([], 0, 0);
        if (response.status === 200) {
          const data = new Uint8Array(response.data.data).buffer;
          const workbook = XLSX.read(data, { type: 'array' });
          XLSX.writeFile(workbook, 'SessionsReports.xlsx');
        }
      }
    } catch (error) {
      // console.log();
    } finally {
      setReportLoadingLoading(false);
    }
  };

  // Отчеты станций XLSX

  const handleChargeXLSX = async () => {
    setStationReportLoading(true);
    try {
      const newFilters: any = [];
      const uniqueFilters = new Set();

      for (const filter of carFiltersResponseData) {
        if (
          (filter.type === 'MULTISELECT' && selectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'TEXT' && textValues[filter.key]) ||
          (filter.type === 'BOOLEAN' && booleanValues[filter.key] !== undefined)
        ) {
          const filterKey = filter.key;

          if (!uniqueFilters.has(filterKey)) {
            uniqueFilters.add(filterKey);

            let values;
            if (filter.type === 'MULTISELECT') {
              values = selectedOptions[filter.key];
            } else if (filter.type === 'TEXT') {
              if (filter.key === 'CUSTOMER') {
                const phoneNumber = textValues[filter.key].startsWith('8') ? textValues[filter.key].replace(/^8/, '+7') : textValues[filter.key];
                const parsedNumber = parsePhoneNumber(phoneNumber)?.number.replace('+', '');
                values = parsedNumber ? [parsedNumber] : [phoneNumber];
              } else {
                values = [textValues[filter.key]];
              }
            } else if (filter.type === 'BOOLEAN') {
              values = [booleanValues[filter.key]];
            }

            newFilters.push({
              key: filter.key,
              values,
            });
          }
        }
      }

      const datesChanged = !isEqual(selectedDates, prevSelectedDates);
      const filtersChanged = !isEqual(prevFilters, newFilters) || datesChanged;

      if (filtersChanged) {
        setFilteredSessionsData(null);
        setFilteredOffset(0);
        setPrevSelectedDates(selectedDates);
      }

      if (selectedDates && selectedDates.length > 0) {
        newFilters.push(
          {
            values: [selectedDates[0]?.toISOString().split('T')[0]],
            key: 'DATE_START'
          },
          {
            values: [selectedDates[1]?.toISOString().split('T')[0]],
            key: 'DATE_END'
          }
        );
      }

      store.setSessionsFilters(newFilters);
      if (newFilters.length > 0) {
        const responseFilter = await ChargeService.chargeReportXLSX(newFilters, 0, 0);
        if (responseFilter.status !== 401) {
          const data = new Uint8Array(responseFilter.data.data).buffer;
          const workbook = XLSX.read(data, { type: 'array' });
          XLSX.writeFile(workbook, 'ChargeReports.xlsx');
        }
      } else {
        const response = await ChargeService.chargeReportXLSX([], 0, 0);
        if (response.status === 200) {
          const data = new Uint8Array(response.data.data).buffer;
          const workbook = XLSX.read(data, { type: 'array' });
          XLSX.writeFile(workbook, 'ChargeReports.xlsx');
        }
      }
    } catch (error) {
      // console.log();
    } finally {
      setStationReportLoading(false);
    }
  };

  // useEffects

  useEffect(() => {
    setPrevFilters(store.sessionFilters);
  }, [store.sessionFilters]);

  useEffect(() => {
    fetchFilters();
    if (!store.stationName) {
      filteredData();
    }
  }, []);

  const areAllSelectedOptionsEmpty = Object.values(selectedOptions).every((array) => array.length === 0);
  const isAnyTextValueNotEmpty = Object.values(textValues).some((value) => value.trim() !== '');
  const isAnyBooleanValueNotEmpty = Object.values(booleanValues).some((value) => value !== undefined && value !== null);
  const areDatesSelected = selectedDates?.length === 2 && selectedDates[0] && selectedDates[1];
  const datesChanged = !isEqual(selectedDates, prevSelectedDates);
  const isRangeValuesNotEmpty = Object.keys(rangeValues).some((key) => rangeValues[key]?.length > 0);
  const rangeConsumptionFilter = carFiltersResponseData.find(
    (filter) => filter.key === 'RANGE_CONSUMPTION'
  );
  const rangeCostFilter = carFiltersResponseData.find(
    (filter) => filter.key === 'RANGE_COST'
  );

  const shouldShowResetFilters =
  !areAllSelectedOptionsEmpty ||
  isAnyTextValueNotEmpty ||
  isAnyBooleanValueNotEmpty ||
  areDatesSelected || isRangeValuesNotEmpty;

  const debounceFilteredData = debounce(filteredData, 500);

  useEffect(() => {
    if (
      (Object.keys(selectedOptions).length > 0 && areAllSelectedOptionsEmpty) ||
      Object.keys(selectedOptions).length > 0 ||
      (Object.keys(textValues).length > 0 && isAnyTextValueNotEmpty) ||
      Object.keys(textValues).length > 0 ||
      (isAnyBooleanValueNotEmpty && Object.keys(booleanValues).length > 0) ||
      Object.keys(booleanValues).length > 0 ||
      areDatesSelected || datesChanged || isRangeValuesNotEmpty
    ) {
      if (!shouldWipeFilters) {
        filteredData();
      }
      store.setStationName('');
      store.setShouldWipeFilters(false);
    }
  }, [selectedOptions, textValues, booleanValues, selectedDates, isRangeValuesChange]);

  return (
    <>
      <div className="carsPage-topLine">
        <div className='carsPage-topLine-title'>
          <h2>Зарядные сессии</h2>
          {sessionsData && sessionsData?.total ? <span>{`(${sessionsData.total})`}</span> : ''}
          {filteredSessionsData && filteredSessionsData?.total ? <span>{`(${filteredSessionsData.total})`}</span> : ''}
        </div>
        <div className='carsPage-topLine-buttons-group'>
          <MediumButton
            disabled={spinnerLoading || sessionsReportLoading}
            text={sessionsReportLoading ? (
              <>
                <Spinner />
                Формирование отчета
              </>
            ) : (
              'Скачать отчет по сессиям'
            )}
            color={'--MyWhite'}
            backgroundColor={'--MyPrimary'}
            onClick={handleSessionsXLSX}
            height='36px'
          />
          <MediumButton
            disabled={spinnerLoading || stationReportLoading}
            text={stationReportLoading ? (
              <>
                <Spinner />
                Формирование отчета
              </>
            ) : (
              'Скачать отчет по станциям'
            )}
            padding='16px'
            onClick={handleChargeXLSX}
            color={'--MyWhite'}
            backgroundColor={'--MyPrimary'}
            height='36px'
          />
        </div>
      </div>
      <div style={{ marginBottom: '30px' }}></div>
      <div style={{ display: (sessionsData?.total !== 0 || filteredSessionsData?.rows) ? '' : 'none' }} className="carsPage-filters-buttons">
        {!spinnerFiltersLoading && carFiltersResponseData && carFiltersResponseData.length > 0 && (
          <>
            {carFiltersResponseData.map((filter, index) => (
              <div key={index}>
                {filter.type === 'MULTISELECT' && (
                  <Select
                    disabled={spinnerLoading}
                    label={filter.name || filter.key}
                    options={filter.options}
                    selectedOptions={selectedOptions[filter.key] || []}
                    onOptionSelect={(selected) => handleOptionSelect(selected, filter.key)}
                    type={filter.type}
                    filterKey={filter.key}
                  />
                )}
                {filter.type === 'TEXT' && (
                  <Select
                    disabled={spinnerLoading}
                    label={filter.name || filter.key}
                    options={[]}
                    selectedOptions={[]}
                    onClearClick={() => handleClearClick(filter.key)}
                    onOptionSelect={(value: string) => handleTextChange(value, filter.key)}
                    type={filter.type}
                    filterKey={filter.key}
                  />
                )}
                {filter.type === 'BOOLEAN' && (
                  <Select
                    disabled={spinnerLoading}
                    label={filter.name || filter.key}
                    options={[]}
                    onClearClick={() => handleBooleanClear(filter.key)}
                    onOptionSelect={(value: boolean) => handleBooleanChange(value, filter.key)}
                    type={filter.type}
                    filterKey={filter.key}
                  />
                )}
                {filter.type === 'RANGE' && filter.key !== 'RANGE_DATE' && (
                  <Select
                    disabled={spinnerLoading}
                    label={filter.name || filter.key}
                    options={[filter.options[0]]}
                    type={filter.type}
                    filterKey={filter.key}
                    onOptionSelect={(values) => handleRangeChange(filter.key, values)}
                    onAcceptRange={(values) => handleAcceptRange(filter.key, values)}
                  />
                )}
                {filter.type === 'RANGE' && filter.key === 'RANGE_DATE' && (
                  <div className='carsPage-rangePicker'>
                    <span>Период</span>
                    <RangePicker
                      size='small'
                      onChange={handleDateChange}
                      format={'DD.MM.YYYY'}
                      value={selectedDates === null ?
                        [
                          dayjs(filter.options[0].minValue),
                          dayjs(filter.options[0].maxValue)
                        ] :
                        selectedDates}
                    />
                  </div>
                )}
              </div>
            ))}
          </>
        )}
        {shouldShowResetFilters
        && (
          <LargeButtonWithIcon
            onClick={handleReset}
            text={'Сбросить фильтры'}
            color={'--MyPrimary'}
            backgroundColor={'--MySecondaryBlue'}
            padding='7px 12px'
          />
        )}
      </div>
      {(sessionsData?.total !== 0 || filteredSessionsData?.rows) && <div style={{ marginBottom: '20px' }}></div>}
      <div style={{ marginBottom: '20px' }}></div>
      {((spinnerLoading && !loading) || spinnerFiltersLoading) && <div className='spinner-container' style={{ display: 'flex', justifyContent: 'center', margin: '50px 0px' }}>
        <MoonLoader color={'#4495D1'}/>
      </div>}
      <div className='carsPage-table'>
        {(sessionsData && !spinnerLoading && !spinnerFiltersLoading) && (
          <div>
            <MyTable
              hasMore={sessionsData.total !== undefined && sessionsData.total > sessionsData.rows.length}
              fetchMore={debounceFilteredData}
              headers={sessionsData.headers}
              data={sessionsData.rows}
              loading={loading}
              onRowClick={(rowData, event) => {
                const href = `/chargingSessions/${rowData._id}`;
                if (event?.ctrlKey || event?.metaKey || event?.button === 1) {
                  event.stopPropagation();
                } else {
                  event?.preventDefault();
                  navigate(href);
                }
              }}
              tableHref={(rowData) => `/chargingSessions/${rowData._id}`}
            />
            <div id="cars-table-bottom-id" style={{ height: '10px' }}></div>
          </div>)}
        {(filteredSessionsData && !spinnerLoading && !spinnerFiltersLoading) && (
          <div>
            <MyTable
              hasMore={filteredSessionsData.total !== undefined
                && filteredSessionsData.total > filteredSessionsData.rows.length}
              fetchMore={debounceFilteredData}
              headers={filteredSessionsData.headers}
              data={filteredSessionsData.rows}
              loading={loading}
              onRowClick={(rowData, event) => {
                const href = `/chargingSessions/${rowData._id}`;
                if (event?.ctrlKey || event?.metaKey || event?.button === 1) {
                  event.stopPropagation();
                } else {
                  event?.preventDefault();
                  navigate(href);
                }
              }}
              tableHref={(rowData) => `/chargingSessions/${rowData._id}`}
            />
            <div id="cars-table-bottom-id" style={{ height: '10px' }}></div>
          </div>)}
      </div>
    </>
  );
};

export default observer(ChargeSessionsPage);
