/* eslint-disable global-require */
import React, { createContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import Store from './store/store';
import 'moment/locale/ru';

interface State {
  store: Store,
}

export const store = new Store();

export const Context = createContext<State>({
  store,
});

const domain = window.location.hostname;

let stylesPath = '';

if (domain.includes('dfm-assist')) {
  stylesPath = './dfIndex.css';
} else {
  stylesPath = './index.css';
}

const loadStylesSync = (path: string) => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = path;
  link.onload = () => {
    document.body.classList.add('styles-loaded');
  };
  document.head.appendChild(link);
};

const RootApp = () => {
  useEffect(() => {
    const load = async () => {
      await import(`${stylesPath}`).then((module) => {
        loadStylesSync(module.default);
      });
    };

    load();
  }, []);

  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <Context.Provider value={{ store }}>
        <ConfigProvider locale={ruRU}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ConfigProvider>
      </Context.Provider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<RootApp />);
