/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './AddCarTestModal.css';
import { useParams } from 'react-router-dom';
import MoonLoader from 'react-spinners/MoonLoader';
import {
  closeModal, largeError, largeFinish
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import MyCheckbox from '../../Checkboxes/Checkbox';
import CarService from '../../../services/CarService';
import { FormTest, TestData, TestResult } from '../../../models/response/CarResponse';

interface AddCarTestProps {
  onClose: () => void;
  isModalOpen: boolean;
  carModel?: string | null;
  checkListId?: string | undefined;
}

const AddCarTestModal: React.FC<AddCarTestProps> = ({
  onClose, isModalOpen, carModel, checkListId
}) => {
  const { carId } = useParams<{ carId: string }>();
  const actualCarId = carId || '';
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [testForm, setTestForm] = useState<FormTest | null>(null);
  const [testResult, setTestResult] = useState<TestResult | null>(null);
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean | string>>({});

  const handleCheckboxChange = (id: string) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleInputChange = (id: string, value: string) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (carModel) {
        const response = await CarService.sendTestForm(actualCarId, checkedItems);
        if (response.status === 200) {
          setShowSuccessMessage(true);
          setStatusMessage('Тестирование завершено');
        }
      }
    } catch (error) {
      setStatusMessage('Возникла проблема при тестировании');
      setShowSuccessMessage(true);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const getTestForm = async () => {
    setLoading(true);
    try {
      const response = await CarService.getTestListForm(actualCarId);
      setTestForm(response.data);
      return response.data;
    } catch (error) {
      // console.error('Ошибка при отправке POST-запроса', error);
    } finally {
      setLoading(false);
    }
  };

  const getTestResult = async () => {
    setLoading(true);
    try {
      if (checkListId) {
        const response = await CarService.getTestListResult(checkListId);
        setTestResult(response.data);
      }
    } catch (error) {
      // console.error('Ошибка при отправке POST-запроса', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (checkListId !== undefined) {
      getTestResult();
    } else {
      getTestForm().then((form) => {
        if (form) {
          const initialCheckedItems: Record<string, boolean | string> = {};
          form.data.forEach((item: TestData) => {
            if (item.entryType === 'boolean') {
              initialCheckedItems[item.id] = false;
            } else {
              initialCheckedItems[item.id] = '';
            }
          });
          setCheckedItems(initialCheckedItems);
        }
      });
    }
  }, [checkListId]);

  return (
    <div className={`addCarTest-modal-background ${isModalOpen ? '' : 'addCarTest-modal-hidden'}`}>
      <div className="addCarTest-modal-content">
        <div className="addCarTest-modal-close" onClick={onClose}>{closeModal}</div>
        {!loading && statusMessage && showSuccessMessage && (
          <div>
            {!showSuccessMessage && statusMessage === 'Возникла проблема при тестировании' ? (
              <div className='addCarTest-modal-notification-container'>
                {largeError}
                <h3 className='addCarTest-modal-notification-title'>{statusMessage}</h3>
                <div className='addCarTest-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'} onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='addCarTest-modal-notification-container'>
                {largeFinish}
                <h3 className='addCarTest-modal-notification-title'>Тестирование завершено</h3>
              </div>
            )}
          </div>
        )}
        {!loading && !statusMessage && !showSuccessMessage && (
          <>
            <form className='addCarTest-modal-form' onSubmit={handleSubmit}>
              <div className='addCarTest-modal-form-container' style={testResult ? { marginBottom: '0px' } : { marginBottom: '24px' }}>
                <div className='addCarTest-modal-form-header'>
                  <h2>{testForm ? testForm?.name : testResult?.name}</h2>
                  <span>{testForm ? testForm?.desc : testResult?.desc}</span>
                </div>
                <div className='addCarTest-modal-form-block'>
                  {testForm?.data.map((item: TestData) => (
                    item.entryType === 'boolean' ? (
                      <div key={item.id} className={`addCarTest-modal-form-checkList-block ${checkedItems[item.id] ? 'checked' : 'unchecked'}`}>
                        <MyCheckbox
                          checked={checkedItems[item.id] === true}
                          onCheckboxChange={() => handleCheckboxChange(item.id)}
                        />
                        <div className='addCarTest-modal-form-checkList-block-container'>
                          <div className='addCarTest-modal-form-checkList-block-container-title'>
                            {item.name}
                          </div>
                          <div className='addCarTest-modal-form-checkList-block-container-options'>
                            <label>{item.desc}</label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div key={item.id} className='addCarTest-modal-form-block'>
                        <span className='addCarTest-modal-form-title'>{item.name}</span>
                        <div className='addCarTest-modal-form-comments-container'>
                          <label className='addCarTest-modal-label'>
                            <textarea
                              placeholder={item.desc || 'Введите текст'}
                              value={checkedItems[item.id] as string || ''}
                              onChange={(e) => handleInputChange(item.id, e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                    )
                  ))}
                  {testResult?.data.map((item: TestData) => (
                    item.entryType === 'boolean' ? (
                    <div key={item.id} className={`addCarTest-modal-form-checkList-block ${Boolean(item.answer) === true ? 'checked' : 'unchecked'}`}>
                      <MyCheckbox
                        checked={Boolean(item.answer)}
                        onCheckboxChange={() => handleCheckboxChange(item.id)}
                        disabled={true}
                      />
                      <div className='addCarTest-modal-form-checkList-block-container'>
                        <div className='addCarTest-modal-form-checkList-block-container-title'>
                          {item.name}
                        </div>
                        <div className='addCarTest-modal-form-checkList-block-container-options'>
                          <label>{item.desc}</label>
                        </div>
                      </div>
                    </div>
                    ) : (
                    <div key={item.id} className='addCarTest-modal-form-block'>
                      <span className='addCarTest-modal-form-title'>{item.name}</span>
                      <div className='addCarTest-modal-form-comments-container'>
                        <label className='addCarTest-modal-label'>
                          <textarea
                            readOnly
                            placeholder={item.desc}
                            value={typeof item.answer === 'string' ? item.answer : ''}
                            onChange={(e) => handleInputChange(item.id, e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                    )
                  ))}
                </div>
              </div>
              {!checkListId && <div className='addCarTest-modal-buttons'>
                <MediumButton text={'Завершить тестирование'} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
                <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
              </div>}
            </form>
          </>)}
        {loading && <div className='spinner-container' style={{ display: 'flex', justifyContent: 'center', margin: '50px 0px' }}>
        <MoonLoader color={'#4495D1'}/>
      </div>}
      </div>
    </div>
  );
};

export default AddCarTestModal;
