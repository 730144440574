/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import AuthService from '../services/AuthService';
import { AuthResponse } from '../models/response/AuthResponse';
import { API_AUTH } from '../http/MyApi';
import OrgService from '../services/OrgService';

interface User {
  _id?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  pushTokens?: string[];
  orgId?: string;
  orgName?: string;
  buttons?: {
    addCar: boolean;
    readyMode: boolean;
    vinEdit: boolean
    travelsVisible: boolean;
    canEditDrivers: boolean
    createOrg: boolean;
    editOrg: boolean;
    addUsersOrg: boolean;
    editUsersOrg: boolean;
    canEditImei: boolean;
  };
 }

export default class Store {
  userToken: AuthResponse = {
    userId: '',
    accessToken: '',
    refreshToken: '',
    capchaToken: ''
  };
  carFilters: { key: string; values: any[] }[] = [];
  sessionFilters: { key: string; values: any[] }[] = [];
  stationFilters: { key: string; values: any[] }[] = [];
  stationsFilters: any = [];
  carId: string = '';
  mapCar: string = '';
  mapStation: string = '';
  user: User = {};
  org = {};
  selectedOrg: string| null = null;
  token: string | null = '';
  refresh: string | null = '';
  isAuth = false;
  total = 0;
  errorName: string | null = null;
  errorMessage: string | null = null;
  errors: { name: string | null; message: string | null }[] = [];
  selectedIds: string[] = [];
  selectedCars: { carId: string; carModel: string, carVin: string }[] = [];
  isLoading: boolean = false;
  tripOffset: number = 0;
  stationName: string | undefined = '';
  stationId: string | undefined = '';
  editGroupModal = false;
  shouldWipeFilters = false;

  constructor() {
    makeAutoObservable(this);
  }

  toggleSelectedId(id: string): void {
    const index = this.selectedIds.indexOf(id);
    if (index > -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(id);
    }
  }

  setError(name: string | null, message: string | null) {
    this.errors.push({ name, message });
  }

  setShouldWipeFilters(shouldWipeFilters: boolean) {
    this.shouldWipeFilters = shouldWipeFilters;
  }

  clearErrors() {
    this.errors = [];
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setEditGroupModal(editGroupModal: boolean) {
    this.editGroupModal = editGroupModal;
  }

  setErrorName(name: string | null) {
    this.errorName = name;
  }

  setErrorMessage(message: string | null) {
    this.errorMessage = message;
  }

  setAuth(bool: boolean) {
    this.isAuth = bool;
  }

  setUser(user: User) {
    this.user = user;
  }

  setTokenUser(userToken: AuthResponse) {
    this.userToken = userToken;
  }

  setToken(token: string) {
    this.token = token;
  }

  setRefresh(refresh: string) {
    this.refresh = refresh;
  }

  setSelectedOrg(selectedOrg: string) {
    this.selectedOrg = selectedOrg;
  }

  setOrg(org: {}) {
    this.org = org;
  }

  setCarId(carId: string) {
    this.carId = carId;
  }

  setMapCar(mapCar: string) {
    this.mapCar = mapCar;
  }

  setMapStation(mapStation: string) {
    this.mapStation = mapStation;
  }

  setCarFilters(filters: { key: string; values: any[] }[]) {
    if (Array.isArray(filters) && filters.every((filter) => filter.key && Array.isArray(filter.values))) {
      this.carFilters = filters;
    }
  }

  setSessionsFilters(filters: { key: string; values: any[] }[]) {
    if (Array.isArray(filters) && filters.every((filter) => filter.key && Array.isArray(filter.values))) {
      this.sessionFilters = filters;
    }
  }

  setStationsFilters(filters: { key: string; values: any[] }[]) {
    if (Array.isArray(filters) && filters.every((filter) => filter.key && Array.isArray(filter.values))) {
      this.stationFilters = filters;
    }
  }

  setStationName(name: string | undefined) {
    this.stationName = name;
  }

  setStationId(id: string | undefined) {
    this.stationId = id;
  }

  setTripOffset(tripOffset: number) {
    this.tripOffset = tripOffset;
  }

  async login(phone: string, code: string) {
    try {
      const response = await AuthService.login(phone, code);
      this.setToken(response.data.accessToken);
      this.setRefresh(response.data.refreshToken);
      this.setAuth(true);
      this.setUser({ _id: response.data.userId });
      // this.getUser();
    } catch (e: any) {
      // console.log(e.response?.data?.message);
      this.setAuth(false);
    }
  }

  async registration(phone?: string, captchaToken?: string) {
    try {
      if (phone && captchaToken) {
        const response = await AuthService.registration(phone, captchaToken);
        this.setAuth(true);
        this.setUser({ _id: response.data.userId });
      }
      // this.getUser();
    } catch (e:any) {
      // console.log(e.response?.data?.message);
    }
  }

  async logout() {
    try {
      await AuthService.logout();
      localStorage.clear();
      this.setAuth(false);
      this.setUser({});
    } catch (e: any) {
      localStorage.clear();
    }
  }

  async checkAuth() {
    try {
      const response = await axios.post<AuthResponse>(
        `${API_AUTH}/auth/refresh-token`,
      );
      this.setUser({ _id: response.data.userId });
      await this.getUser();
      // return true;
    } catch (e: any) {
      // console.log(e.response?.data?.message);
      return false;
    }
  }

  async getUser() {
    try {
      this.setLoading(true);
      const response = await AuthService.getUser();
      const myUser = response.data;
      this.setUser(myUser);
    } catch (e:any) {
      // console.log(e.response?.data?.message);
    } finally {
      this.setLoading(false);
    }
  }

  async getOrg() {
    try {
      const response = await OrgService.myOrg();
      const myOrgData = response.data;
      this.setOrg(myOrgData.rows);
      this.total = myOrgData.total;
      return myOrgData;
    } catch (e:any) {
      // console.log(e.response?.data?.message);
    }
  }

  async loginOrg(orgId: string) {
    try {
      const response = await OrgService.loginOrg(orgId);

      localStorage.setItem('token', response.data.accessToken);
      // localStorage.setItem('refresh', response.data.refreshToken);
      this.setSelectedOrg(orgId);
      this.setAuth(true);
      this.setUser({ _id: response.data.userId });
      // this.getUser();
    } catch (e: any) {
      // this.setAuth(false);
    }
  }

  async logoutOrg() {
    try {
      const response = await OrgService.logout();
      localStorage.setItem('token', response.data.accessToken);
      // localStorage.setItem('refresh', response.data.refreshToken);
      this.setAuth(true);
      this.setUser({ _id: response.data.userId });
      // this.getUser();
    } catch (e: any) {
      // console.log(e.response?.data?.message);
    }
  }
}
