/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './ChargeSessionInfoPage.css';
import { useParams } from 'react-router-dom';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';
import moment from 'moment';
import { Skeleton } from 'antd';
import ChargeService from '../../services/ChargeService';
import { SessionData } from '../../models/response/ChargeResponse';
import { SessionAnalyticsData } from '../../models/response/AnalyticsResponse';
import { statusColors, statusSessionDescriptions, statusTextColors } from '../../components/Table/MyTable';

const ChargeSessionInfoPage = () => {
  const { chargingSessionId } = useParams<{ chargingSessionId: string }>();
  const actualSessionId = chargingSessionId || '';
  const [sessionInfo, setSessionInfo] = useState<SessionData>();
  const [analyticsData, setAnalyticsData] = useState<SessionAnalyticsData[]>([]);
  const [chartData, setChartData] = useState<any>({ accumInfo: [], chargeAmount: [], energyUsage: [] });
  const [formattedTime, setFormattedTime] = useState('');
  const [loading, setLoading] = useState(false);

  function formatPhoneNumber(phoneNumber: string | undefined) {
    const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }
    return phoneNumber;
  }

  const fetchSession = async () => {
    try {
      const response = await ChargeService.getSessionById(actualSessionId);
      if (response.data) {
        setSessionInfo(response.data);
        const startedAt = moment(sessionInfo?.sessionDetails.chargingPeriod.startedAt);
        const endedAt = moment(sessionInfo?.sessionDetails.chargingPeriod.endedAt);
        const duration = moment.duration(endedAt.diff(startedAt));
        const durationFormatted = `${Math.floor(duration.asHours()).toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`;
        setFormattedTime(durationFormatted);
      }
    } catch {
      // console.log();
    }
  };

  const getAnalyticsData = async () => {
    setLoading(true);
    try {
      const response = await ChargeService.getAnalyticsSession(actualSessionId);
      if (response.data) {
        setAnalyticsData(response.data);
        const formattedData = {
          accumInfo: response.data[0].accumInfo.map((item) => ({
            soc: item.soc,
            createdAt: moment(item.createdAt).format('HH:mm:ss')
          })),
          chargeAmount: response.data[0].chargeAmount.map((item) => ({
            meterValue: item.meterValue,
            createdAt: moment(item.createdAt).format('HH:mm:ss')
          })),
          energyUsage: response.data[0].energyUsage.map((item) => ({
            powerOutput: item.powerOutput,
            createdAt: moment(item.createdAt).format('HH:mm:ss')
          }))
        };
        setChartData(formattedData);
      }
    } catch (error) {
      // console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Tooltips

  const CustomAccumulatorTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const sectionData = payload[0]?.payload;
      return (
        <div className='analyticsPage-toolTip'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <p style={{ fontSize: '10px', color: '#D1D1D1' }}>{label}</p>
            <p style={{ display: 'flex', gap: '6px' }}><p>Аккумулятор</p><p style={{ fontWeight: '500' }}>{`${sectionData.soc}%`}</p></p>
          </div>
        </div>
      );
    }
    return null;
  };

  const CustomChargeTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const sectionData = payload[0]?.payload;
      return (
        <div className='analyticsPage-toolTip'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <p style={{ fontSize: '10px', color: '#D1D1D1' }}>{label}</p>
            <p style={{ display: 'flex', gap: '6px' }}><p>Общий заряд</p><p style={{ fontWeight: '500' }}>{`${sectionData.meterValue} кВт/ч`}</p></p>
          </div>
        </div>
      );
    }
    return null;
  };

  const CustomPowerTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const sectionData = payload[0]?.payload;
      return (
        <div className='analyticsPage-toolTip'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <p style={{ fontSize: '10px', color: '#D1D1D1' }}>{label}</p>
            <p style={{ display: 'flex', gap: '6px' }}><p>Мощность</p><p style={{ fontWeight: '500' }}>{`${sectionData.powerOutput} кВт`}</p></p>
          </div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    fetchSession();
    getAnalyticsData();
  }, []);

  return (
    <div className='session-page-info'>
      <div className='session-page-info-header'>
        <h2 className='session-page-info-title'>Сессия {actualSessionId}</h2>
        <div className='session-page-info-title-status-container'>
          {sessionInfo?.sessionDetails.status && <span
            className='session-page-session-status'
            style={{ backgroundColor: sessionInfo?.sessionDetails.status ? statusColors[sessionInfo?.sessionDetails.status] : '#FDEDED', color: sessionInfo?.sessionDetails.status ? statusTextColors[sessionInfo?.sessionDetails.status] : '#EA4B4B' }}
          >
            {statusSessionDescriptions[sessionInfo?.sessionDetails.status] || sessionInfo?.sessionDetails.status}
          </span>}
        </div>
      </div>
      {/* <span className='session-page-info-header-partner'>Партнер: {sessionInfo?.memberDetails.partner ? sessionInfo?.memberDetails.partner : '-'}</span> */}
      <div className='session-page-info-container'>
        <div className='session-page-info-container-indicators'>
          <div className='session-info-container-small'>
            <div className='session-info-container'>
              <div className='session-info-indications-container'>
                <div className='session-info-indications-header'>
                  <div className='session-info-indications-title-container'>
                    <h3 className='session-info-indications-header-title'>{sessionInfo?.addressDetails.address ? sessionInfo?.addressDetails.address : '-'}</h3>
                  </div>
                  <span>{sessionInfo?.addressDetails.chargerName ? sessionInfo?.addressDetails.chargerName : '-'}</span>
                </div>
                <div className='session-info-indications-list'>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>ID локации</span>
                      <span className='session-info-indication-value' title={sessionInfo?.addressDetails.locationId}>{sessionInfo?.addressDetails.locationId ? sessionInfo?.addressDetails.locationId : '-'}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>ID EVSE</span>
                      <span className='session-info-indication-value' title={sessionInfo?.addressDetails.evseId}>{sessionInfo?.addressDetails.evseId ? sessionInfo?.addressDetails.evseId : '-'}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>ID коннектора</span>
                      <span className='session-info-indication-value' title={sessionInfo?.addressDetails.connectorId}>{sessionInfo?.addressDetails.connectorId ? sessionInfo?.addressDetails.connectorId : '-'}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>ID локации партнера</span>
                      <span className='session-info-indication-value'>-</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>ID EVSE партнера</span>
                      <span className='session-info-indication-value' title={sessionInfo?.addressDetails.partnerEvseId ? sessionInfo?.addressDetails.partnerEvseId : '-'}>{sessionInfo?.addressDetails.partnerEvseId ? sessionInfo?.addressDetails.partnerEvseId : '-'}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>ID коннектора партнера</span>
                      <span className='session-info-indication-value' title={sessionInfo?.addressDetails.partnerConnectorId}>{sessionInfo?.addressDetails.partnerConnectorId ? sessionInfo?.addressDetails.partnerConnectorId : '-'}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>Тип коннектора</span>
                      <span className='session-info-indication-value'>{sessionInfo?.addressDetails.connectorType ? sessionInfo?.addressDetails.connectorType : '-'}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>Номер коннектора</span>
                      <span className='session-info-indication-value'>{sessionInfo?.addressDetails.connectorNum ? sessionInfo?.addressDetails.connectorNum : '-'}</span>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className='session-info-container-small'>
            <div className='session-info-container'>
              <div className='session-info-indications-container'>
                <div className='session-info-indications-header'>
                  <div className='session-info-indications-title-container'>
                    <h3 className='session-info-indications-header-title'>Данные о сессии</h3>
                  </div>
                </div>
                <div className='session-info-indications-list'>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>Начало – конец</span>
                      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }}>
                        <span className='session-info-indication-value'>
                          {moment(sessionInfo?.sessionDetails.chargingPeriod.startedAt).format('HH:mm:ss')} - {sessionInfo?.sessionDetails.chargingPeriod.endedAt ? moment(sessionInfo?.sessionDetails.chargingPeriod.endedAt).format('HH:mm:ss') : '?'}
                        </span>
                        <span style={{ fontSize: '12px' }} className='session-info-indication-value'>
                          {moment(sessionInfo?.sessionDetails.chargingPeriod.startedAt).format('DD.MM.YYYY')}
                        </span>
                      </div>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>Продолжительность</span>
                      <span className='session-info-indication-value'>{sessionInfo?.sessionDetails.chargingPeriod.endedAt ? formattedTime : '?'}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>Потребление</span>
                      <span className='session-info-indication-value'>{sessionInfo?.sessionDetails.loaded ? sessionInfo?.sessionDetails.loaded : '-'} кВт•ч</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>Стоимость</span>
                      <span className='session-info-indication-value'>{sessionInfo?.sessionDetails.cost ? sessionInfo?.sessionDetails.cost : '-'} ₽</span>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className='session-info-container-small'>
            <div className='session-info-container'>
              <div className='session-info-indications-container'>
                <div className='session-info-indications-header'>
                  <div className='session-info-indications-title-container'>
                    <h3 className='session-info-indications-header-title'>Данные клиента</h3>
                  </div>
                </div>
                <div className='session-info-indications-list'>
                    <div className='session-info-indication'>
                      <span className='session-info-indication-title'>ID</span>
                      <span>{sessionInfo?.memberDetails.uuid ? sessionInfo?.memberDetails.uuid : '-'}</span>
                    </div>
                    {sessionInfo?.memberDetails.phone &&
                      <>
                        <div className='session-info-indications-separator'></div>
                        <div className='session-info-indication'>
                          <span className='session-info-indication-title'>Номер телефона</span>
                          <span>{sessionInfo?.memberDetails.phone ? formatPhoneNumber(sessionInfo?.memberDetails.phone) : '-'}</span>
                        </div>
                      </>
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='session-page-info-container-graphs'>
          <div className='session-page-info-graph-block'>
            <div className='session-page-info-graph-container'>
              <h3>Аккумулятор, %</h3>
              {chartData.accumInfo.length > 0 && !loading &&
                (<div className='session-page-info-graph'>
                  <ResponsiveContainer width={840} height={300}>
                    <AreaChart data={chartData.accumInfo}>
                      <CartesianGrid vertical={false} opacity={0.3} stroke='#D1D1D1'/>
                      <XAxis
                        dataKey="createdAt"
                        tick={{ fontSize: '10px', fill: '#9A99A2' }}
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        tickMargin={10}
                        interval="preserveStart"
                        />
                      <YAxis tick={{ fontSize: 10, fill: '#9A99A2' }}/>
                      <Tooltip content={CustomAccumulatorTooltip}/>
                      <defs>
                        <linearGradient id="colorSoc" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor="#4495D1" stopOpacity={0.4} />
                          <stop offset="100%" stopColor="#4495D1" stopOpacity={0.05} />
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="soc"
                        stroke="#4495D133"
                        fill="url(#colorSoc)"
                        dot={{
                          stroke: '#4495D1', fill: '#4495D1', r: 4
                        }}/>
                    </AreaChart>
                  </ResponsiveContainer>
                </div>)}
                {loading && <div style={{ width: '820px', overflowX: 'hidden', overflowY: 'hidden' }}>
                  <Skeleton.Node active style={{ display: 'flex', width: '10000px', height: '300px' }} />
                </div>}
                {(chartData.accumInfo.length === 0 && !loading) &&
              <div style={{
                width: '820px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'
              }}>
                <span>Нет данных</span>
              </div>
              }
            </div>
          </div>
          <div className='session-page-info-graph-block'>
            <div className='session-page-info-graph-container'>
              <h3>Общий заряд, кВт•ч</h3>
              {chartData.chargeAmount.length > 0 && !loading &&
                (<div className='session-page-info-graph'>
                  <ResponsiveContainer width={840} height={300}>
                      <AreaChart data={chartData.chargeAmount}>
                        <CartesianGrid vertical={false} opacity={0.3} stroke='#D1D1D1'/>
                        <XAxis
                          dataKey="createdAt"
                          tick={{ fontSize: '10px', fill: '#9A99A2' }}
                          angle={-45}
                          textAnchor="end"
                          height={60}
                          tickMargin={10}
                          interval="preserveStart"
                        />
                        <YAxis tick={{ fontSize: 10, fill: '#9A99A2' }}/>
                        <Tooltip content={CustomChargeTooltip}/>
                        <defs>
                          <linearGradient id="colorMeterValue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#4495D1" stopOpacity={0.4} />
                            <stop offset="100%" stopColor="#4495D1" stopOpacity={0.05} />
                          </linearGradient>
                        </defs>
                        <Area
                          type="monotone"
                          dataKey="meterValue"
                          stroke="#4495D133"
                          fill='url(#colorMeterValue)'
                          dot={{
                            stroke: '#4495D1', fill: '#4495D1', r: 4
                          }}/>
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>)}
                {loading &&
                <div style={{ width: '820px', overflowX: 'hidden', overflowY: 'hidden' }}>
                  <Skeleton.Node active style={{ display: 'flex', width: '10000px', height: '300px' }} />
                </div>}
              {(chartData.chargeAmount.length === 0 && !loading) &&
              <div style={{
                width: '820px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'
              }}>
                <span>Нет данных</span>
              </div>
              }
            </div>
          </div>
          <div className='session-page-info-graph-block'>
            <div className='session-page-info-graph-container'>
              <h3>Мощность, кВт</h3>
              {(chartData.energyUsage.length > 0 && !loading) &&
                (<div className='session-page-info-graph'>
                <ResponsiveContainer width={840} height={300}>
                    <AreaChart data={chartData.energyUsage}>
                      <CartesianGrid vertical={false} opacity={0.3} stroke='#D1D1D1'/>
                      <XAxis
                        dataKey="createdAt"
                        tick={{ fontSize: '10px', fill: '#9A99A2' }}
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        tickMargin={10}
                        interval="preserveStart"
                      />
                      <YAxis tick={{ fontSize: 10, fill: '#9A99A2' }}/>
                      <Tooltip content={CustomPowerTooltip}/>
                      <defs>
                        <linearGradient id="colorPowerOutput" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor="#4495D1" stopOpacity={0.4} />
                          <stop offset="100%" stopColor="#4495D1" stopOpacity={0.05} />
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="powerOutput"
                        stroke="#4495D133"
                        fill='url(#colorPowerOutput)'
                        dot={{
                          stroke: '#4495D1', fill: '#4495D1', r: 4
                        }}/>
                    </AreaChart>
                  </ResponsiveContainer>
                </div>)}
              {loading && <div style={{ width: '820px', overflowX: 'hidden', overflowY: 'hidden' }}>
                <Skeleton.Node active style={{ display: 'flex', width: '10000px', height: '300px' }} />
              </div>}
              {(chartData.energyUsage.length === 0 && !loading) &&
              <div style={{
                width: '820px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'
              }}>
                <span>Нет данных</span>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChargeSessionInfoPage;
