import { OCPIConnectorsMap } from '../../components/Table/MyTable';

// Станции

interface ChargeFilterItem {
  name: string;
  key: string;
  type: 'MULTISELECT' | 'TEXT' | 'BOOLEAN';
  options: (string | number | boolean)[];
  icons?: {
    dark?: Record<string, string>;
    light?: Record<string, string>;
    [key: string]: Record<string, string> | undefined;
  };
}

export type ChargeFilterResponse = ChargeFilterItem[];

export interface Connector {
  _id: string;
  type: keyof typeof OCPIConnectorsMap;
  status: string;
  power: string;
  powerType: string;
  format: string;
  tariffIds: string[];
  rate: {
    _id: string;
    title: string;
    price: number;
  }
}

export interface ChargePointResponse {
  name: string,
  address: string,
  city: string,
  evses: {
    uid: string;
    connectors: Connector[]
  };
  status: string,
  location: {
    latitude: string,
    longitude: string
  },
  _id: string
}

interface Coordinate {
  latitude: string;
  longitude: string;
}

export type Evse = {
  uid?: string;
  connectors: Connector[]
}

export interface Station {
  _id: string;
  address: string;
  city: string;
  location: Coordinate;
  country: string;
  country_code: string;
  directions: any[];
  evses: Evse[]
  facilities: any[];
  id: string;
  images: any[];
  name: string;
  related_locations: any[];
  time_zone: string;
  reservable: boolean;
  status: string;
  identifier: string;
  vendor: string;
  model: string;
  incomePrice: string;
  publish: boolean
}

export interface StationLogsResponse {
  previousPageToken: string;
  nextPageToken: string;
  messages: string;
  timestamp: string;
}

export interface PointResponse {
  status_code: number;
  timestamp: string;
  data: Station;
}

export interface StationsResponse {
  rows: Station[],
  headers: any[],
  offset: number,
  limit: number,
  total: number
}

export interface ResetResponse {
  status: string;
  time: string;
}

// Сессии
// Таблица

interface ChargingPeriod {
  startedAt: string;
  endedAt: string;
}

interface SessionRow {
  partner: string;
  sessionId: string;
  locationId: string;
  address: string;
  connectorType: string;
  status: string;
  chargingPeriod: ChargingPeriod;
  power: number;
  connectorPower: number;
  price: number;
  isOurStation: boolean;
}

interface SessionHeader {
  title: string;
  type: string;
  valueKey: string;
  width: string;
}

export interface SessionsTableData {
  limit: number;
  offset: number;
  total: number;
  rows: SessionRow[];
  headers: SessionHeader[];
}

// Сессиия Id

interface AddressDetails {
  address: string;
  chargerName: string
  locationId: string;
  evseId: string;
  connectorId: string;
  partnerEvseId: string;
  partnerConnectorId: string;
  connectorType: string;
  connectorNum: number;
}

interface SessionDetails {
  chargingPeriod: ChargingPeriod;
  duration: string;
  loaded: number;
  cost: number;
  status: string;
}

interface MemberDetails {
  uuid: string;
  partner: string;
  fullName: string;
  phone: string;
}

export interface SessionData {
  addressDetails: AddressDetails;
  sessionDetails: SessionDetails;
  memberDetails: MemberDetails;
}

// Создание станции

export enum ChargeVoltage {
  AC_1_PHASE = 'AC_1_PHASE',
  AC_2_PHASE = 'AC_2_PHASE',
  AC_2_PHASE_SPLIT = 'AC_2_PHASE_SPLIT',
  AC_3_PHASE = 'AC_3_PHASE',
  DC = 'DC'
}

export enum ChargeSocketType {
  SOCKET = 'SOCKET',
  CABLE = 'CABLE'
}

export interface ConnectorCreate {
  standard: string;
  max_electric_power: number;
  power_type: string;
  format: string;
  id?: string;
}

export interface EVSECreate {
  connectors: ConnectorCreate[];
}

interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface ChargerCreate {
  evses?: EVSECreate[];
  model?: string;
  vendor?: string;
  identifier?: string;
  coordinates?: Coordinates;
  city?: string;
  name?: string;
  address?: string;
  incomePrice?: string;
}
