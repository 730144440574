/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import './AnalyticsChargePage.css';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  Area,
  PieChart,
  Cell,
  Pie
} from 'recharts';
import { DatePicker, Skeleton } from 'antd';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import dayjs, { Dayjs } from 'dayjs';
import { debounce, isEqual } from 'lodash';
import MoonLoader from 'react-spinners/MoonLoader';
import moment from 'moment';
import ReactDOM from 'react-dom';
import ChargeService from '../../services/ChargeService';
import {
  AnalyticsFilterResponse, ResponseAnalyticsData, SessionInfo,
  TotalInfo
} from '../../models/response/AnalyticsResponse';
import {
  charging, connectors28pxIcons, error, graphicsBar, graphicsPie, profitMoney, successSession
} from '../../Icons/Icons';
import Select from '../../components/Select/Select';
import { Context } from '../..';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';

const AnalyticsChargePage = () => {
  const [selectedGraphicType, setSelectedGraphicType] = useState('bar');
  const { store } = useContext(Context);
  const [analyticsData, setAnalyticsData] = useState<ResponseAnalyticsData | null>(null);
  const [analyticsFilters, setAnalyticsFilters] = useState<AnalyticsFilterResponse | null>(null);
  const [chartData, setChartData] = useState<any[]>([]);
  const [pieDataKwh, setPieDataKwh] = useState<any[]>([]);
  const [pieDataExpenseProfit, setPieDataExpenseProfit] = useState<any[]>([]);
  const [pieDataSessions, setPieDataSessions] = useState<any[]>([]);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [validSessions, setValidSessions] = useState(0);
  const [invalidSessions, setInvalidSessions] = useState(0);

  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  // Фильтры
  const { RangePicker } = DatePicker;
  const [selectedBarFilter, setSelectedBarFilter] = useState<string>('day');

  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [textValues, setTextValues] = useState<{ [key: string]: string }>({});
  const [booleanValues, setBooleanValues] = useState<{ [key: string]: boolean | undefined}>({});
  const [selectedDates, setSelectedDates] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [prevSelectedDates, setPrevSelectedDates] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [rangeValues, setRangeValues] = useState<{ [key: string]: any[] }>({});
  const [prevFilters, setPrevFilters] = useState<any[]>([]);

  const handleTextChange = debounce((text: string, key: string) => {
    const phoneNumber = text.replace(/\D/g, '');
    setTextValues((prevTextValues) => ({
      ...prevTextValues,
      [key]: phoneNumber,
    }));
  }, 1500);

  const handleOptionSelect = debounce((selected: string[], filterKey: string) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [filterKey]: selected,
    }));
  }, 500);

  const handleBooleanChange = debounce((value: boolean, key: string) => {
    if (booleanValues[key] !== value) {
      setBooleanValues((prevBooleanValues) => ({
        ...prevBooleanValues,
        [key]: value,
      }));
    }
  }, 500);

  const handleClearClick = debounce((filterKey: string) => {
    setTextValues((prevValues) => ({ ...prevValues, [filterKey]: '' }));
  }, 1000);

  const handleBooleanClear = debounce((filterKey: string) => {
    setLoading(true);
    if (booleanValues[filterKey] !== undefined) {
      setBooleanValues((prevValues) => ({
        ...prevValues,
        [filterKey]: undefined,
      }));
    }
    setLoading(false);
  }, 500);

  const handleRangeChange = debounce((key: any, newValues: any) => {
    setRangeValues((prevValues) => ({
      ...prevValues,
      [key]: newValues,
    }));
  }, 500);

  const handleDateChange = (dates: any) => {
    if ((dates && dates !== null)) {
      setSelectedDates(dates);
    } else {
      setSelectedDates(null);
    }
  };

  const fetchAnalyticsFilters = async () => {
    try {
      const response = await ChargeService.getAnalyticsFilters();
      if (response.status !== 401) {
        setAnalyticsFilters(response.data);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleClickBarFilter = (filter: string) => {
    setSelectedBarFilter(filter);
  };

  const processChartData = (data: any) => {
    const result: { [sectionValue: string]: { [connectorType: string]: any } } = {};

    data.rows.details.forEach((detail: { sectionData: any[] }) => {
      detail.sectionData.forEach((section: { sectionValue: string, sessionData: any[], totalInfo: TotalInfo }) => {
        if (!result[section.sectionValue]) {
          result[section.sectionValue] = {};
        }

        const {
          commonSessionCount, commonSessionProfit, commonSessionKwh, commonExpense
        } = section.totalInfo;

        let totalValidSessions = 0;
        let totalInvalidSessions = 0;

        section.sessionData.forEach((session: {
          connectorType: string;
          totalKwh: number;
          totalProfit: number;
          totalSessions: number;
          totalExpense: number;
          validSessions: number;
          invalidSessions: number;
        }) => {
          const { connectorType } = session;

          if (!result[section.sectionValue][connectorType]) {
            result[section.sectionValue][connectorType] = {
              connectorTotalKwh: session.totalKwh,
              connectorTotalProfit: session.totalProfit,
              connectorTotalSessions: session.totalSessions,
              connectorTotalExpense: session.totalExpense,
              connectorValidSessions: session.validSessions,
              connectorInvalidSessions: session.invalidSessions
            };
          } else {
            result[section.sectionValue][connectorType].connectorTotalKwh += session.totalKwh;
            result[section.sectionValue][connectorType].connectorTotalProfit += session.totalProfit;
            result[section.sectionValue][connectorType].connectorTotalSessions += session.totalSessions;
            result[section.sectionValue][connectorType].connectorTotalExpense += session.totalExpense;
            result[section.sectionValue][connectorType].connectorValidSessions += session.validSessions || 0;
            result[section.sectionValue][connectorType].connectorInvalidSessions += session.invalidSessions || 0;
          }

          totalValidSessions += session.validSessions || 0;
          totalInvalidSessions += session.invalidSessions || 0;
        });

        result[section.sectionValue].totalSessions = commonSessionCount;
        result[section.sectionValue].totalKwh = commonSessionKwh;
        result[section.sectionValue].totalExpense = commonExpense;
        result[section.sectionValue].totalProfit = commonSessionProfit;
        result[section.sectionValue].totalValidSessions = totalValidSessions;
        result[section.sectionValue].totalInvalidSessions = totalInvalidSessions;
        result[section.sectionValue].totalExpenseProfitSum = commonExpense + commonSessionProfit;
      });
    });

    return Object.keys(result).map((sectionValue) => {
      const dataPoint: { [key: string]: any } = { sectionValue };

      Object.keys(result[sectionValue]).forEach((connectorType) => {
        if (
          connectorType !== 'totalSessions' &&
          connectorType !== 'totalKwh' &&
          connectorType !== 'totalExpense' &&
          connectorType !== 'total' &&
          connectorType !== 'totalValidSessions' &&
          connectorType !== 'totalInvalidSessions' &&
          connectorType !== 'totalExpenseProfitSum'
        ) {
          dataPoint[connectorType] = result[sectionValue][connectorType];
        }
      });

      dataPoint.totalSessions = result[sectionValue].totalSessions;
      dataPoint.totalKwh = result[sectionValue].totalKwh;
      dataPoint.totalExpense = result[sectionValue].totalExpense;
      dataPoint.totalProfit = result[sectionValue].totalProfit;
      dataPoint.totalValidSessions = result[sectionValue].totalValidSessions;
      dataPoint.totalInvalidSessions = result[sectionValue].totalInvalidSessions;
      dataPoint.totalExpenseProfitSum = result[sectionValue].totalExpenseProfitSum;

      return dataPoint;
    });
  };

  const processPieData = (chartData: any) => {
    const kwhData: any = [];
    const expenseProfitData: any = [];
    const sessionsData: any = [];

    let totalExpense = 0;
    let totalProfit = 0;
    let totalValidSessions = 0;
    let totalInvalidSessions = 0;

    chartData.forEach((item: any) => {
      Object.keys(item).forEach((key) => {
        if (
          key !== 'sectionValue' &&
                key !== 'totalExpense' &&
                key !== 'totalExpenseProfitSum' &&
                key !== 'totalInvalidSessions' &&
                key !== 'totalKwh' &&
                key !== 'totalProfit' &&
                key !== 'totalSessions' &&
                key !== 'totalValidSessions'
        ) {
          const existingConnector = kwhData.find((connector: any) => connector.name === key);

          if (existingConnector) {
            existingConnector.connectorTotalKwh += item[key].connectorTotalKwh;
          } else {
            kwhData.push({
              name: key,
              connectorTotalKwh: item[key].connectorTotalKwh,
            });
          }
        }
      });

      totalExpense += item.totalExpense;
      totalProfit += item.totalProfit;
      totalValidSessions += item.totalValidSessions;
      totalInvalidSessions += item.totalInvalidSessions;
    });

    expenseProfitData.push({
      name: 'Расход',
      value: totalExpense,
    });
    expenseProfitData.push({
      name: 'Выручка',
      value: totalProfit,
    });

    sessionsData.push({
      name: 'Успешные',
      value: totalValidSessions,
    });
    sessionsData.push({
      name: 'С ошибками',
      value: totalInvalidSessions,
    });

    return { kwhData, expenseProfitData, sessionsData };
  };

  const getPieLegendData = (pieData: any[], valueKey: string) => {
    const totalValue = pieData.reduce((sum, connector) => sum + connector[valueKey], 0);

    return pieData.map((connector) => ({
      name: connector.name,
      totalValue: connector[valueKey],
      percentage: ((connector[valueKey] / totalValue) * 100).toFixed(2),
    }));
  };

  const calculateTotals = (data: ResponseAnalyticsData) => {
    let totalProfitSum = 0;
    let totalExpenseSum = 0;
    let validSessions = 0;
    let invalidSessions = 0;

    data.rows.details.forEach((detail) => {
      detail.sectionData.forEach((section) => {
        section.sessionData.forEach((session) => {
          totalProfitSum += session.totalProfit;
          totalExpenseSum += session.totalExpense;
          validSessions += session.validSessions;
          invalidSessions += session.invalidSessions;
        });
      });
    });

    setTotalProfit(totalProfitSum);
    setTotalExpense(totalExpenseSum);
    setValidSessions(validSessions);
    setInvalidSessions(invalidSessions);
  };

  const fetchAnalyticsData = async () => {
    setLoading(true);
    try {
      const newFilters: any = [];
      const uniqueFilters = new Set();
      if (selectedBarFilter === 'day') {
        newFilters.push({ key: 'DAY_SECTION', values: [true] });
      } else if (selectedBarFilter === 'week') {
        newFilters.push({ key: 'WEEK_SECTION', values: [true] });
      } else if (selectedBarFilter === 'month') {
        newFilters.push({ key: 'MONTH_SECTION', values: [true] });
      }
      if (analyticsFilters) {
        for (const filter of analyticsFilters) {
          if (
            (filter.type === 'MULTISELECT' && selectedOptions[filter.key]?.length > 0) ||
            (filter.type === 'TEXT' && textValues[filter.key]) ||
            (filter.type === 'BOOLEAN' && booleanValues[filter.key] !== undefined)
          ) {
            const filterKey = filter.key;

            if (!uniqueFilters.has(filterKey)) {
              uniqueFilters.add(filterKey);

              let values;
              if (filter.type === 'MULTISELECT') {
                values = selectedOptions[filter.key];
              } else if (filter.type === 'TEXT') {
                values = [textValues[filter.key]];
              } else if (filter.type === 'BOOLEAN') {
                values = [booleanValues[filter.key]];
              }

              newFilters.push({
                key: filter.key,
                values,
              });
            }
          }
        }
      }

      const datesChanged = !isEqual(selectedDates, prevSelectedDates);
      store.setCarFilters(newFilters);
      const filtersChanged = !isEqual(prevFilters, newFilters) || datesChanged;

      if (filtersChanged) {
        setPrevSelectedDates(selectedDates);
      }

      if (selectedDates && selectedDates.length > 0) {
        newFilters.push(
          {
            key: 'RANGE_DATE',
            values: [
              `${selectedDates[0]?.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
                .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`,
              `${selectedDates[1]?.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999)
                .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`
            ],
          }
        );
      }
      const response = await ChargeService.analyticsSearch(newFilters);
      if (response.status !== 401) {
        setAnalyticsData(response.data);
        const processedData = processChartData(response.data);
        const { kwhData, expenseProfitData, sessionsData } = processPieData(processedData);
        setChartData(processedData);
        setPieDataKwh(kwhData);
        setPieDataExpenseProfit(expenseProfitData);
        setPieDataSessions(sessionsData);
        if (response.data) {
          calculateTotals(response.data);
        }
      }
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    } finally {
      setLoading(false);
    }
  };

  const connectorColors: { [key: string]: string } = {
    'CCS/SAE': '#4BCB99',
    'CCS Combo 2': '#4BCB99',
    'GBT (DC)': '#64A3EB',
    'Type 2': '#AB80E3',
    CHAdeMO: '#FC56AF',
    'Type 1': '#FBA742',
    'IEC 62196 T1': '#9A99A2',
    'GB/T AC': '#FBD913',
    Tesla: '#F16384',
    'Tesla S': '#1CD0CD',
    CHAOJI: '#0F0F0F',
    'Domestic F': '#EA4B4B'
  };

  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    const monthMatch = dateString.match(/^(\d{4})-(\d{2})$/);
    if (monthMatch) {
      const [, year, month] = monthMatch;
      const monthNames = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
      return `${monthNames[Number(month) - 1]}`;
    }

    const weekMatch = dateString.match(/^(\d{4})-W(\d{1,2})$/);
    if (weekMatch) {
      const [, yearStr, weekStr] = weekMatch;
      const year = Number(yearStr);
      const week = Number(weekStr);

      const firstDateOfWeek = new Date(year, 0, 1 + (week - 1) * 7);
      const lastDateOfWeek = new Date(year, 0, 1 + week * 7 - 1);

      const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}.${month}`;
      };

      return `${formatDate(firstDateOfWeek)} - ${formatDate(lastDateOfWeek)}`;
    }
    return `${day}.${month}`;
  };

  const getMonthInGenitive = (date: Date) => {
    const monthNamesInGenitive = [
      'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
      'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ];
    return monthNamesInGenitive[date.getMonth()];
  };

  const formatDateForTooltip = (dateString: string) => {
    const weekMatch = dateString.match(/^(\d{4})-W(\d{1,2})$/);
    const monthMatch = dateString.match(/^(\d{4})-(\d{2})$/);

    if (weekMatch) {
      const [, yearStr, weekStr] = weekMatch;
      const year = Number(yearStr);
      const week = Number(weekStr);

      const firstDateOfWeek = new Date(year, 0, 1 + (week - 1) * 7);
      const lastDateOfWeek = new Date(year, 0, 1 + week * 7 - 1);

      const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}.${month}`;
      };

      return `${formatDate(firstDateOfWeek)} - ${formatDate(lastDateOfWeek)}`;
    }

    if (monthMatch) {
      const [, yearStr, monthStr] = monthMatch;
      const year = Number(yearStr);
      const month = Number(monthStr) - 1;
      const date = new Date(year, month, 1);

      const monthNameInGenitive = getMonthInGenitive(date);
      return `${monthNameInGenitive} ${year}`;
    }

    const date = new Date(dateString);
    return format(date, 'd MMMM yyyy', { locale: ru }).replace(/^./, (str) => str.toUpperCase());
  };

  const CustomBarSessionsTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const sectionData = payload[0]?.payload;

      const { totalSessions } = sectionData;

      const connectorDetails = Object.keys(sectionData)
        .filter((key) => key !== 'sectionValue' && key !== 'totalIncome' && key !== 'totalSessions' && key !== 'totalKwh' && key !== 'totalExpense' && key !== 'totalProfit' && key !== 'totalInvalidSessions' && key !== 'totalValidSessions' && key !== 'invalidSessions' && key !== 'validSessions' && key !== 'totalExpenseProfitSum')
        .map((connectorType) => {
          const session = sectionData[connectorType];

          if (!session) {
            return `${connectorType}: нет данных`;
          }

          const sessionCount = session.connectorTotalSessions;
          const sessionPercentage = ((sessionCount / totalSessions) * 100).toFixed(1);

          return `${connectorType} · ${session.connectorTotalKwh.toLocaleString('ru-RU')} кВт · ${session.connectorTotalProfit.toLocaleString('ru-RU')} ₽ · ${sessionCount.toLocaleString('ru-RU')} сессий (${sessionPercentage}%)`;
        });

      return (
        <div className='analyticsPage-toolTip'>
          <p style={{ fontSize: '10px', color: '#D1D1D1' }}>
            {`${formatDateForTooltip(label)} ${
              /^(\d{4})-W\d{1,2}$/.test(label) ? label.split('-')[0] : ''
            }`}</p>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {connectorDetails.map((detail: string, index: number) => {
              const connectorType = detail.split(' · ')[0].trim();
              return (
                <p
                  key={index}
                  style={{
                    borderBottom: `2px solid ${connectorColors[connectorType] || '#D1D1D1'}`,
                    paddingBottom: '4px',
                    fontWeight: '500',
                    width: 'fit-content'
                  }}
                >
                  {detail}
                </p>
              );
            })}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <p style={{ fontSize: '10px', color: '#D1D1D1' }}>Всего</p>
            <p style={{ display: 'flex', gap: '6px' }}>Доход <p style={{ fontWeight: '500' }}>{`${(sectionData.totalProfit).toLocaleString('ru-RU')} ₽`}</p></p>
            <p style={{ display: 'flex', gap: '6px' }}><p>Сессий</p><p style={{ fontWeight: '500' }}>{`${sectionData.totalSessions.toLocaleString('ru-RU')}`}</p></p>
            <p style={{ display: 'flex', gap: '6px' }}><p>Потреблено энергии</p><p style={{ fontWeight: '500' }}>{`${(sectionData.totalKwh).toLocaleString('ru-RU')} кВт`}</p></p>
          </div>
        </div>
      );
    }
    return null;
  };

  const CustomProfitSessionsTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const sectionData = payload[0]?.payload;

      return (
        <div className='analyticsPage-toolTip'>
          <p style={{ fontSize: '10px', color: '#D1D1D1' }}>
            {`${formatDateForTooltip(label)} ${
              /^(\d{4})-W\d{1,2}$/.test(label) ? label.split('-')[0] : ''
            }`}</p>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <p style={{ display: 'flex', gap: '6px' }}><p>Выручка</p><p style={{ fontWeight: '500' }}>{`${(sectionData.totalProfit).toLocaleString('ru-RU')} ₽`}</p></p>
            <p style={{ display: 'flex', gap: '6px' }}><p>Расход</p><p style={{ fontWeight: '500' }}>{`${(sectionData.totalExpense).toLocaleString('ru-RU')} ₽`}</p></p>
          </div>
        </div>
      );
    }
    return null;
  };

  const CustomBarSuccessSessionsTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const sectionData = payload[0]?.payload;

      const connectorDetails = Object.keys(sectionData)
        .filter(
          (key) => key !== 'sectionValue' &&
            key !== 'totalIncome' &&
            key !== 'totalSessions' &&
            key !== 'totalKwh' &&
            key !== 'totalExpense' &&
            key !== 'totalProfit' &&
            key !== 'totalInvalidSessions' &&
            key !== 'totalValidSessions' &&
            key !== 'totalExpenseProfitSum'
        )
        .map((connectorType) => {
          const session = sectionData[connectorType];
          if (!session) {
            return `${connectorType}: нет данных`;
          }

          return `${connectorType}: ${session.connectorValidSessions} · ${session.connectorInvalidSessions}`;
        });

      return (
        <div className='analyticsPage-toolTip'>
          <p style={{ fontSize: '10px', color: '#D1D1D1' }}>
            {`${formatDateForTooltip(label)} ${
              /^(\d{4})-W\d{1,2}$/.test(label) ? label.split('-')[0] : ''
            }`}</p>
          <div style={{ display: 'flex', gap: '16px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <p style={{ fontSize: '10px', color: '#D1D1D1' }}>Всего сессий</p>
              <p style={{ display: 'flex', gap: '6px' }}>
                <span>Успешных</span>
                <span
                  style={{
                    fontWeight: '500',
                    borderBottom: '2px solid #4BCB99',
                    paddingBottom: '2px',
                  }}
                >
                  {sectionData.totalValidSessions.toLocaleString('ru-RU')}
                </span>
              </p>
              <p style={{ display: 'flex', gap: '6px' }}>
                <span>С ошибкой</span>
                <span
                  style={{
                    fontWeight: '500',
                    borderBottom: '2px solid #EA4B4B',
                    paddingBottom: '2px',
                  }}
                >
                  {sectionData.totalInvalidSessions.toLocaleString('ru-RU')}
                </span>
              </p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <p style={{ fontSize: '10px', color: '#D1D1D1' }}>По коннекторам</p>
              {connectorDetails.map((detail: string, index: number) => {
                const [connectorType, counts] = detail.split(':');
                const [validCount, invalidCount] = counts.split('·').map((item) => item.trim());
                return (
                  <div
                    key={index}
                    style={{
                      fontWeight: '500',
                      display: 'flex',
                      gap: '6px'
                    }}
                  >
                    {connectorType}
                    <span
                      style={{
                        borderBottom: '2px solid #4BCB99',
                        paddingBottom: '2px',
                      }}
                    >
                      {Number(validCount).toLocaleString('ru-RU')}
                    </span>
                    <span
                      style={{
                        borderBottom: '2px solid #EA4B4B',
                        paddingBottom: '2px',
                      }}
                    >
                      {Number(invalidCount).toLocaleString('ru-RU')}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const totalValue = pieDataKwh.reduce((acc, connector) => acc + connector.connectorTotalKwh, 0);
      const percentage = ((payload[0].value / totalValue) * 100).toFixed(2);

      const successfulPercentage = (((validSessions + invalidSessions) / validSessions) * 100).toFixed(2);
      const errorPercentage = (((validSessions + invalidSessions) / invalidSessions) * 100).toFixed(2);

      const borderColor = connectorColors[payload[0].name] || '#D1D1D1';

      return (
        <div style={{
          backgroundColor: '#333',
          color: '#fff',
          borderRadius: '8px',
          padding: '10px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
          fontSize: '12px'
        }}>
          {
            (payload[0].name !== 'Расход' &&
              payload[0].name !== 'Выручка' &&
              payload[0].name !== 'Успешные' &&
              payload[0].name !== 'С ошибками'
            ) &&
          <p style={{
            borderBottom: `2px solid ${borderColor}`,
            paddingBottom: '2px',
            fontWeight: '500'
          }}>
            {`${payload[0].name} · ${payload[0].value.toLocaleString('ru-RU')} кВт · (${percentage}%)`}
          </p>}
          {
          (payload[0].name === 'Расход' ||
          payload[0].name === 'Выручка') &&
          <p style={{
            borderBottom: `2px solid ${payload[0].name === 'Расход' ? '#FFBB38' : '#5CBF63'}`,
            paddingBottom: '2px',
            fontWeight: '500'
          }}>
            {`${payload[0].name} · ${payload[0].value.toLocaleString('ru-RU')} ₽`}
          </p>
          }
          {payload[0].name === 'Успешные' &&
          <p style={{
            borderBottom: '2px solid #4BCB99',
            paddingBottom: '2px',
            fontWeight: '500'
          }}>
            {`${payload[0].name} · ${payload[0].value.toLocaleString('ru-RU')} · ${successfulPercentage}%`}
          </p>
          }
          {payload[0].name === 'С ошибками' &&
          <p style={{
            borderBottom: '2px solid #FF5F5F',
            paddingBottom: '2px',
            fontWeight: '500'
          }}>
            {`${payload[0].name} · ${payload[0].value.toLocaleString('ru-RU')} · ${errorPercentage}%`}
          </p>
          }
        </div>
      );
    }
    return null;
  };

  const SquareDot = (props: any) => {
    const {
      cx, cy, fill, stroke
    } = props;
    return (
      <rect
        x={cx - 4}
        y={cy - 4}
        width={8}
        height={8}
        fill={fill}
        stroke={stroke}
      />
    );
  };

  const CustomTick = (props: any) => {
    const { x, y, payload } = props;
    const formattedValue = formatDate(payload.value);

    const lines = formattedValue.split(/\s*-\s*/);
    return (
      <g transform={`translate(${x},${y})`}>
        {lines.length === 2 ? (
          <>
            <text
              x={0}
              y={0}
              dy={12}
              textAnchor="middle"
              fill="#D1D1D1"
              fontSize={10}
            >
              {`${lines[0]} -`}
            </text>
            <text
              x={0}
              y={12}
              dy={12}
              textAnchor="middle"
              fill="#D1D1D1"
              fontSize={10}
            >
              {lines[1]}
            </text>
          </>
        ) : (
          <text
            x={0}
            y={0}
            dy={12}
            textAnchor="middle"
            fill="#D1D1D1"
            fontSize={10}
          >
            {formattedValue}
          </text>
        )}
      </g>
    );
  };

  // Общее

  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const xAxisProps = {
    dataKey: 'sectionValue',
    axisLine: false,
    tickLine: false,
    interval: 0,
    height: 1,
    scale: 'band',
    xAxisId: 'year',
  };

  const extractYear = (dateStr: any) => {
    if (dateStr.includes('W')) {
      return parseInt(dateStr.split('-')[0], 10);
    }
    return new Date(dateStr).getFullYear();
  };

  const cellWidth = containerWidth && chartData.length > 0 ? containerWidth / chartData.length : 38;

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.scrollWidth);
      }
    };
    updateWidth();

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [chartData]);

  const renderYearLineAndLabel = (data: any[], xAxisProps: any, yPos: number) => {
    const years = data.map((item: any) => extractYear(item.sectionValue));
    const uniqueYears = [...new Set(years)];

    return uniqueYears.map((year: number, index: number) => {
      const yearStartIndex = years.indexOf(year);
      const yearEndIndex = years.lastIndexOf(year);

      const startX = yearStartIndex * cellWidth;
      const endX = (yearEndIndex + 1) * cellWidth;

      const midX = (startX + endX) / 2;

      return (
        <g key={year}>
          <line x1={startX} x2={endX} y1={yPos} y2={yPos} stroke="#D1D1D1" strokeWidth={1} />
          <text x={midX} y={yPos + 15} textAnchor="middle" fill="#D1D1D1" fontSize="10px">
            {year}
          </text>

          {index === 0 && (
            <g>
              <line x1={startX + 6} x2={startX} y1={yPos + 2} y2={yPos} stroke="#D1D1D1" strokeWidth={1} />
              <line x1={startX + 6} x2={startX} y1={yPos - 2} y2={yPos} stroke="#D1D1D1" strokeWidth={1} />
            </g>
          )}

          {index === uniqueYears.length - 1 && (
            <g>
              <line x1={endX} x2={endX - 6} y1={yPos} y2={yPos - 2} stroke="#D1D1D1" strokeWidth={1} />
              <line x1={endX} x2={endX - 6} y1={yPos} y2={yPos + 2} stroke="#D1D1D1" strokeWidth={1} />
            </g>
          )}

          {index < uniqueYears.length - 1 && (
            <circle cx={endX} cy={yPos} r={2} fill="#D1D1D1" />
          )}
        </g>
      );
    });
  };

  // ---------

  const handleReset = () => {
    setLoading(true);
    try {
      store.setShouldWipeFilters(true);
      setTextValues({});
      setSelectedOptions({});
      setBooleanValues({});
      setRangeValues({});
      handleDateChange(null);
    } finally {
      setLoading(false);
    }
  };

  const connectors = analyticsData?.rows.sessionInfo.map((info) => info.connectorType);
  const pieLegendKwhData = getPieLegendData(pieDataKwh, 'connectorTotalKwh');
  const pieLegendProfitData = getPieLegendData(pieDataExpenseProfit, 'value');
  const pieLegendValidData = getPieLegendData(pieDataSessions, 'value');

  useEffect(() => {
    if (!firstLoading) {
      fetchAnalyticsData();
    }
    setFirstLoading(false);
    store.setShouldWipeFilters(false);
  }, [selectedBarFilter, selectedOptions, textValues, booleanValues, selectedDates]);

  useEffect(() => {
    fetchAnalyticsFilters();
    fetchAnalyticsData();
    setFirstLoading(false);
  }, []);

  useEffect(() => {
    setPrevFilters(store.carFilters);
  }, [store.carFilters]);

  const areAllSelectedOptionsEmpty = Object.values(selectedOptions).every((array) => array.length === 0);
  const isAnyTextValueNotEmpty = Object.values(textValues).some((value) => value.trim() !== '');
  const isAnyBooleanValueNotEmpty = Object.values(booleanValues).some((value) => value !== undefined && value !== null);
  const areDatesSelected = selectedDates?.length === 2 && selectedDates[0] && selectedDates[1];
  const datesChanged = !isEqual(selectedDates, prevSelectedDates);
  const isRangeValuesNotEmpty = Object.keys(rangeValues).some((key) => rangeValues[key]?.length > 0);

  const shouldShowResetFilters =
  !areAllSelectedOptionsEmpty ||
  isAnyTextValueNotEmpty ||
  isAnyBooleanValueNotEmpty ||
  areDatesSelected || datesChanged;

  return (
    <div className='analyticsPage'>
      <div className="analyticsPage-topLine">
        <div className='analyticsPage-topLine-title'>
          <h2>Аналитика</h2>
        </div>
      </div>
      <div className='analyticsPage-container'>
        <div className='analyticsPage-container-filters'>
          <div className='analyticsPage-diagrams-filter'>
            <div className='analyticsPage-diagrams-switcher'>
              <div
                onClick={() => setSelectedGraphicType('bar')}
                className={`analyticsPage-diagrams-button ${selectedGraphicType === 'bar' ? 'analyticsPage-diagrams-button-selected' : ''}`}
              >
                {graphicsBar}
                столбчатая диаграмма/график
              </div>
              <div
                onClick={() => setSelectedGraphicType('pie')}
                className={`analyticsPage-diagrams-button ${selectedGraphicType === 'pie' ? 'analyticsPage-diagrams-button-selected' : ''}`}
              >
                {graphicsPie}
                круговая диаграмма
              </div>
            </div>
          </div>
          <div className='analyticsPage-search-filters'>
          {shouldShowResetFilters
            && (
              <LargeButtonWithIcon
                onClick={handleReset}
                text={'Сбросить фильтры'}
                color={'--MyPrimary'}
                backgroundColor={'--MySecondaryBlue'}
                padding='7px 12px'
              />
            )}
            {analyticsFilters?.map((filter, index) => {
              const renderFilter = () => {
                if (filter.type === 'MULTISELECT') {
                  return (
                    <Select
                      disabled={loading}
                      label={filter.name || filter.key}
                      options={filter.options}
                      selectedOptions={selectedOptions[filter.key] || []}
                      onOptionSelect={(selected) => handleOptionSelect(selected, filter.key)}
                      type={filter.type}
                      filterKey={filter.key}
                    />
                  );
                } if (filter.type === 'TEXT') {
                  return (
                    <Select
                      disabled={loading}
                      label={filter.name || filter.key}
                      options={[]}
                      selectedOptions={[]}
                      onClearClick={() => handleClearClick(filter.key)}
                      onOptionSelect={(value: string) => handleTextChange(value, filter.key)}
                      type={filter.type}
                      filterKey={filter.key}
                    />
                  );
                } if (filter.type === 'RANGE' && filter.key === 'RANGE_DATE') {
                  return (
                    <div className='carsPage-rangePicker'>
                      <span>Период</span>
                      <RangePicker
                        size='small'
                        onChange={handleDateChange}
                        format={'DD.MM.YYYY'}
                        value={selectedDates === null ?
                          [
                            dayjs(filter.options[0].minValue),
                            dayjs(filter.options[0].maxValue)
                          ] :
                          selectedDates}
                          dropdownAlign={{
                            points: ['tl', 'bl'],
                            offset: [13, 10],
                            targetOffset: [0, 0],
                          }}
                      />
                    </div>
                  );
                }
                return null;
              };
              const filterContent = renderFilter();
              return filterContent ? <div key={index}>{filterContent}</div> : null;
            })}
          </div>
        </div>
        {selectedGraphicType === 'bar' && chartData.length > 0 &&
        <div className='analyticsPage-charts-list'>
          <div className='analyticsPage-chart-container'>
            <div className='analyticsPage-chart-header'>
              <div className='analyticsPage-chart-title'>
                <h3>Зарядные сессии</h3>
                <div className='analyticsPage-chart-connectors-total'>
                  {analyticsData?.rows.sessionInfo.map((session: SessionInfo) => (
                    <div key={session.connectorType} className='analyticsPage-chart-connector-container'>
                      <div className='analyticsPage-chart-connectors-svg'
                          dangerouslySetInnerHTML={{ __html: connectors28pxIcons[session.connectorType] || '' }} />
                      <div className='analyticsPage-chart-connector-title-container'>
                        <span className='analyticsPage-chart-svg-title'>{session.connectorType}</span>
                        <span className='analyticsPage-chart-svg-count'>{session.sessionCount.toLocaleString('ru-RU')}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='analyticsPage-bar-filters'>
                <ul>
                  <button disabled={loading} className={selectedBarFilter === 'month' ? 'selected-filter' : ''} onClick={() => handleClickBarFilter('month')}>Месяц</button>
                  <button disabled={loading} className={selectedBarFilter === 'week' ? 'selected-filter' : ''} onClick={() => handleClickBarFilter('week')}>Неделя</button>
                  <button disabled={loading} className={selectedBarFilter === 'day' ? 'selected-filter' : ''} onClick={() => handleClickBarFilter('day')}>День</button>
                </ul>
              </div>
            </div>
            <div>
              {chartData && chartData.length > 0 && !loading ? (
              <div style={{ display: 'flex', position: 'relative' }}>
                <div
                 style={{
                   width: '61px',
                   backgroundColor: 'white',
                   position: 'absolute',
                   zIndex: 1
                 }}>
                  <ResponsiveContainer height={350}>
                    <BarChart data={chartData} margin={{ bottom: 30 }}>
                      <YAxis
                        dataKey='totalKwh'
                        tick={{ fontSize: 10, fill: '#9A99A2' }}
                      />
                      <CartesianGrid vertical={false} />
                      <XAxis
                        tick={false}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div ref={containerRef} style={{
                  overflowX: 'auto', overflowY: 'hidden', marginLeft: '60px', width: '100%'
                }}>
                  <ResponsiveContainer width={chartData.length > 35 ? (38 * chartData.length) : '100%'} height={350}>
                    <BarChart
                      data={chartData}
                      barSize={16}
                      barCategoryGap={22}
                      margin={{ bottom: 30 }}
                    >
                      <CartesianGrid vertical={false} />
                      <XAxis dataKey="sectionValue" tick={<CustomTick />} interval={0} />
                      {renderYearLineAndLabel(chartData, xAxisProps, 330)}
                      <Tooltip content={CustomBarSessionsTooltip} cursor={{ fill: '#F7F7F7' }} />
                      {connectors?.map((connector, index) => {
                        const isLastBar = index === connectors.length - 1;
                        return (
                          <Bar
                            key={connector}
                            dataKey={`${connector}.connectorTotalKwh`}
                            stackId="a"
                            fill={connectorColors[connector] || '#8884d8'}
                            radius={isLastBar ? [3, 3, 0, 0] : [0, 0, 0, 0]}
                            stroke={isLastBar ? '#fff' : 'none'}
                            strokeWidth={isLastBar ? 0.5 : 0}
                          />
                        );
                      })}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              ) :
              <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
                <Skeleton.Node active style={{ width: '10000px', height: '350px' }} />
              </div>
              }
            </div>
            <div className='analyticsPage-connectorsList'>
              {connectors?.map((connector) => (
                <div key={connector} className='analyticsPage-connector-container'>
                  <span className='analyticsPage-connector-title'>{connector}</span>
                  <span
                    className='analyticsPage-connector-underline'
                    style={{ backgroundColor: connectorColors[connector] }}
                  ></span>
                </div>
              ))}
            </div>
          </div>
          <div className='analyticsPage-chart-container'>
            <div className='analyticsPage-chart-header'>
              <div className='analyticsPage-chart-title'>
                <h3>Доходы и расходы</h3>
                <div className='analyticsPage-chart-connectors-total'>
                  <div className='analyticsPage-chart-connector-container'>
                    <div className='analyticsPage-chart-profit-svg'>{profitMoney}</div>
                    <div className='analyticsPage-chart-connector-title-container'>
                      <span className='analyticsPage-chart-svg-title'>Выручка, ₽</span>
                      <span className='analyticsPage-chart-svg-count'>{totalProfit.toLocaleString('ru-RU')}</span>
                    </div>
                  </div>
                  <div className='analyticsPage-chart-connector-container'>
                    <div className='analyticsPage-chart-charging-svg'>{charging}</div>
                    <div className='analyticsPage-chart-connector-title-container'>
                      <span className='analyticsPage-chart-svg-title'>Расход, ₽</span>
                      <span className='analyticsPage-chart-svg-count'>{totalExpense.toLocaleString('ru-RU')}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='analyticsPage-bar-filters'>
                <ul>
                  <button disabled={loading} className={selectedBarFilter === 'month' ? 'selected-filter' : ''} onClick={() => handleClickBarFilter('month')}>Месяц</button>
                  <button disabled={loading} className={selectedBarFilter === 'week' ? 'selected-filter' : ''} onClick={() => handleClickBarFilter('week')}>Неделя</button>
                  <button disabled={loading} className={selectedBarFilter === 'day' ? 'selected-filter' : ''} onClick={() => handleClickBarFilter('day')}>День</button>
                </ul>
              </div>
            </div>
            {chartData && !loading ? (
            <div style={{ display: 'flex', position: 'relative' }}>
              <div
              style={{
                width: '61px',
                backgroundColor: 'white',
                position: 'absolute',
                zIndex: 1
              }}>
                <ResponsiveContainer height={350}>
                  <BarChart data={chartData} margin={{ bottom: 30 }}>
                    <YAxis
                      dataKey='totalProfit'
                      tick={{ fontSize: 10, fill: '#9A99A2' }}
                    />
                    <CartesianGrid vertical={false} />
                    <XAxis
                      tick={false}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div
              ref={containerRef}
              style={{
                overflowX: 'auto', overflowY: 'hidden', marginLeft: '60px', width: '100%'
              }}>
                <ResponsiveContainer width={chartData.length > 35 ? (38 * chartData.length) : '100%'} height={350}>
                  <AreaChart
                    data={chartData}
                    barSize={16}
                    barCategoryGap={22}
                    margin={{ bottom: 30 }}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      dataKey="sectionValue"
                      tick={<CustomTick />}
                      interval={0}
                      padding={{ left: 20, right: 20 }}
                      z={4}
                    />
                    <Tooltip content={CustomProfitSessionsTooltip}/>
                    <Area
                      type="monotone"
                      dataKey="totalExpense"
                      stroke="#FFBB38"
                      fillOpacity={1}
                      fill="#FFBB3833"
                      dot={<SquareDot fill="#FFBB38" stroke="#FFBB38" />}
                    />
                    <Area
                      type="monotone"
                      dataKey='totalProfit'
                      stroke="#5CBF63"
                      fillOpacity={0.5}
                      fill="#5CBF6333"
                      dot={{
                        stroke: '#5CBF63', fill: '#5CBF63', r: 4, fillOpacity: '#5CBF63'
                      }}
                    />
                  {renderYearLineAndLabel(chartData, xAxisProps, 330)}
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            ) :
            <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
              <Skeleton.Node active style={{ width: '10000px', height: '350px' }} />
            </div>
            }
            <div className='analyticsPage-connectorsList'>
              <div className='analyticsPage-connector-container'>
                <span className='analyticsPage-connector-title'>Доходы</span>
                <span
                  className='analyticsPage-connector-underline'
                  style={{ backgroundColor: '#5CBF63' }}
                >
                </span>
              </div>
              <div className='analyticsPage-connector-container'>
                <span className='analyticsPage-connector-title'>Расходы на электроэнергию</span>
                <span
                  className='analyticsPage-connector-underline'
                  style={{ backgroundColor: '#FFBB38' }}
                >
                </span>
              </div>
            </div>
          </div>
          <div className='analyticsPage-chart-container'>
            <div className='analyticsPage-chart-header'>
              <div className='analyticsPage-chart-title'>
                <h3>Зарядные сессии</h3>
                <div className='analyticsPage-chart-connectors-total'>
                  <div className='analyticsPage-chart-connectors-total'>
                    <div className='analyticsPage-chart-connector-container'>
                      <div className='analyticsPage-chart-success-svg'>{successSession}</div>
                      <div className='analyticsPage-chart-connector-title-container'>
                        <span className='analyticsPage-chart-svg-title'>Успешные</span>
                        <span className='analyticsPage-chart-svg-count'>{validSessions.toLocaleString('ru-RU')}</span>
                      </div>
                    </div>
                    <div className='analyticsPage-chart-connector-container'>
                      <div className='analyticsPage-chart-error-svg'>{error}</div>
                      <div className='analyticsPage-chart-connector-title-container'>
                        <span className='analyticsPage-chart-svg-title'>С ошибками</span>
                        <span className='analyticsPage-chart-svg-count'>{invalidSessions.toLocaleString('ru-RU')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='analyticsPage-bar-filters'>
                <ul>
                  <button disabled={loading} className={selectedBarFilter === 'month' ? 'selected-filter' : ''} onClick={() => handleClickBarFilter('month')}>Месяц</button>
                  <button disabled={loading} className={selectedBarFilter === 'week' ? 'selected-filter' : ''} onClick={() => handleClickBarFilter('week')}>Неделя</button>
                  <button disabled={loading} className={selectedBarFilter === 'day' ? 'selected-filter' : ''} onClick={() => handleClickBarFilter('day')}>День</button>
                </ul>
              </div>
            </div>
            {chartData && !loading ? (
            <div style={{ display: 'flex', position: 'relative' }}>
              <div style={{
                width: '61px',
                backgroundColor: 'white',
                position: 'absolute',
                zIndex: 1
              }}>
                <ResponsiveContainer height={350}>
                  <BarChart data={chartData} margin={{ bottom: 30 }}>
                    <YAxis
                      dataKey='totalSessions'
                      tick={{ fontSize: 10, fill: '#9A99A2' }}
                    />
                    <CartesianGrid vertical={false} />
                    <XAxis
                      tick={false}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div
              ref={containerRef}
               style={{
                 overflowX: 'auto', overflowY: 'hidden', width: '100%', marginLeft: '60px', marginBottom: '30px'
               }}>
                <ResponsiveContainer width={chartData.length > 35 ? (38 * chartData.length) : '100%'} height={350}>
                  <BarChart
                    margin={{ bottom: 30 }}
                    data={chartData}
                    barSize={16}
                    barCategoryGap={22}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      dataKey="sectionValue"
                      tick={<CustomTick />}
                      interval={0}
                    />
                    {renderYearLineAndLabel(chartData, xAxisProps, 330)}
                    <Tooltip content={CustomBarSuccessSessionsTooltip} cursor={{ fill: '#F7F7F7' }} />
                    <Bar
                      stroke={'#fff'}
                      strokeWidth={0.5}
                      dataKey="totalInvalidSessions"
                      stackId="b"
                      fill="#FF5F5F"
                    />
                    <Bar
                      stroke={'#fff'}
                      strokeWidth={0.5}
                      dataKey="totalValidSessions"
                      stackId="b"
                      fill="#4BCB99"
                      radius={[3, 3, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            ) : (
              <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
                <Skeleton.Node active style={{ width: '10000px', height: '350px' }} />
              </div>
            )}
            <div className='analyticsPage-connectorsList'>
              <div className='analyticsPage-connector-container'>
                <span className='analyticsPage-connector-title'>Успешные</span>
                <span
                  className='analyticsPage-connector-underline'
                  style={{ backgroundColor: '#4BCB99' }}
                >
                </span>
              </div>
              <div className='analyticsPage-connector-container'>
                <span className='analyticsPage-connector-title'>С ошибками</span>
                <span
                  className='analyticsPage-connector-underline'
                  style={{ backgroundColor: '#FF5F5F' }}
                >
                </span>
              </div>
            </div>
          </div>
          </div>}
          {(selectedGraphicType === 'pie' && chartData.length > 0) &&
          <div className='analyticsPage-pie-list'>
            <div className='analyticsPage-pie-container'>
              <div className='analyticsPage-chart-header'>
                <div className='analyticsPage-chart-title'>
                  <h3>Зарядные сессии</h3>
                </div>
              </div>
              {chartData && !loading ? (<div className='analyticsPage-pieChart-container'>
              <ResponsiveContainer width={240} height={240}>
                <PieChart>
                    <Pie
                      data={pieDataKwh}
                      innerRadius={60}
                      outerRadius={120}
                      dataKey="connectorTotalKwh"
                      nameKey="name"
                      cornerRadius={5}
                    >
                      {pieDataKwh.map((connector, index) => (
                        <Cell key={connector.name} fill={connectorColors[connector.name] || '#8884d8'} />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                </PieChart>
              </ResponsiveContainer>
              <div className='analyticsPage-pieChart-legend'>
                {pieLegendKwhData.map((connector) => (
                  <div key={connector.name} className='analyticsPage-pieChart-dot-connector'>
                    <span className='analyticsPage-pieChart-legend-dot' style={{ backgroundColor: connectorColors[connector.name] }}></span>
                    <span className='analyticsPage-connector-title'>
                        {connector.name} {connector.totalValue.toLocaleString('ru-RU')} кВт · {connector.percentage}%
                    </span>
                  </div>
                ))}
              </div>
              </div>)
                : (
                  <div style={{
                    overflowX: 'hidden', overflowY: 'hidden', width: '600px'
                  }}>
                    <Skeleton.Node active style={{ width: '10000px', height: '268px' }} />
                  </div>
                )}
            </div>
            <div className='analyticsPage-pie-container'>
              <div className='analyticsPage-chart-header'>
                <div className='analyticsPage-chart-title'>
                  <h3>Доходы и расходы</h3>
                </div>
              </div>
              {chartData && !loading ? (<div className='analyticsPage-pieChart-container'>
              <ResponsiveContainer width={240} height={240}>
                <PieChart>
                  <Pie
                    data={pieDataExpenseProfit}
                    innerRadius={60}
                    outerRadius={120}
                    dataKey="value"
                    nameKey="name"
                    cornerRadius={5}
                  >
                    {pieDataExpenseProfit.map((item, index) => (
                      <Cell key={item.name} fill={item.name === 'Выручка' ? '#5CBF63' : '#FFBB38'} />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                </PieChart>
              </ResponsiveContainer>
                <div className='analyticsPage-pieChart-legend'>
                  {pieLegendProfitData.map((money) => (
                    <div key={money.name} className='analyticsPage-pieChart-dot-connector'>
                      <span className='analyticsPage-pieChart-legend-dot' style={{ backgroundColor: money.name === 'Выручка' ? '#5CBF63' : '#FFBB38' }}></span>
                      <span className='analyticsPage-connector-title'>
                        {money.name} {money.totalValue.toLocaleString('ru-RU')} ₽
                      </span>
                    </div>
                  ))}
                </div>
              </div>)
                : (
                  <div style={{
                    overflowX: 'hidden', overflowY: 'hidden', width: '600px'
                  }}>
                    <Skeleton.Node active style={{ width: '10000px', height: '268px' }} />
                  </div>
                )}
            </div>
            <div className='analyticsPage-pie-container'>
              <div className='analyticsPage-chart-header'>
                <div className='analyticsPage-chart-title'>
                  <h3>Зарядные сессии</h3>
                </div>
              </div>
              {chartData && !loading ? (<div className='analyticsPage-pieChart-container'>
              <ResponsiveContainer width={240} height={240}>
                <PieChart>
                  <Pie
                    data={pieDataSessions}
                    innerRadius={60}
                    outerRadius={120}
                    dataKey="value"
                    nameKey="name"
                    cornerRadius={5}
                  >
                    {pieDataSessions.map((item, index) => (
                      <Cell key={item.name} fill={item.name === 'Успешные' ? '#4BCB99' : '#FF5F5F'}/>
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                </PieChart>
              </ResponsiveContainer>
                <div className='analyticsPage-pieChart-legend'>
                  {pieLegendValidData.map((valid) => (
                    <div key={valid.name} className='analyticsPage-pieChart-dot-connector'>
                      <span className='analyticsPage-pieChart-legend-dot' style={{ backgroundColor: valid.name === 'Успешные' ? '#4BCB99' : '#FF5F5F' }}></span>
                      <span className='analyticsPage-connector-title'>
                        {valid.name} {valid.totalValue.toLocaleString('ru-RU')} · {valid.percentage}%
                      </span>
                    </div>
                  ))}
                </div>
              </div>)
                : (
                  <div style={{
                    overflowX: 'hidden', overflowY: 'hidden', width: '600px'
                  }}>
                    <Skeleton.Node active style={{ width: '10000px', height: '268px' }} />
                  </div>
                )}
            </div>
          </div>}
          {chartData.length <= 0 && !loading && (selectedGraphicType === 'pie' || selectedGraphicType === 'bar') &&
          <div style={{
            textAlign: 'center', padding: '20px', height: '350px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#4495D1'
          }}>
            <p>Данных нет</p>
          </div>}
          {chartData.length <= 0 && loading && (selectedGraphicType === 'pie' || selectedGraphicType === 'bar') &&
          <div className='spinner-container' style={{ display: 'flex', justifyContent: 'center', margin: '50px 0px' }}>
          <MoonLoader color={'#4495D1'}/>
        </div>}
      </div>
    </div>
  );
};

export default AnalyticsChargePage;
