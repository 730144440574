/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext, useEffect, useReducer, useState
} from 'react';
import './RatePage.css';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import {
  bucket, edit, plus
} from '../../Icons/Icons';
import AddRateDetailedModal from '../../components/Modal/AddRateDetailed/AddRateDetailedModal';
import ChargeService from '../../services/ChargeService';
import { RateInfo, RateElement, RateData } from '../../models/response/RateResponse';
import DeleteRateModal from '../../components/Modal/DeleteRate/DeleteRateModal';
import UpdateRateDetailedModal from '../../components/Modal/UpdateRate/UpdateRateDetailedModal';
import Select from '../../components/Select/Select';
import { FilterResponse } from '../../models/response/CarResponse';
import { Context } from '../..';

interface RateDataArr {
  rows: RateInfo[];
  headers: any[];
  total?: number
}

const RatePage = () => {
  const { store } = useContext(Context);
  const [isAddRateModalOpen, setAddRateModalOpen] = useState(false);
  const [isDeleteRateModalOpen, setIsDeleteRateModalOpen] = useState(false);
  const [isUpdateRateModalOpen, setIsUpdateRateModalOpen] = useState(false);
  const [selectedRate, setSelectedRate] = useState<RateInfo | null>(null);
  const [rates, setRates] = useState<RateDataArr | null>(null);
  const [filters, setFilters] = useState(false);
  const [loading, setLoading] = useState(false);

  const [rateFiltersResponseData, setRateFiltersResponseData] = useState<FilterResponse>([]);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [textValues, setTextValues] = useState<{ [key: string]: string }>({});
  const [booleanValues, setBooleanValues] = useState<{ [key: string]: boolean | undefined}>({});

  // Модалка добавления тарифа

  const handleAddRateModalOpen = () => {
    setAddRateModalOpen(true);
  };

  const handleAddRateModalClose = () => {
    setAddRateModalOpen(false);
  };

  // Модалка удаления тарифа

  const handleDeleteRateModalOpen = (rate: RateInfo) => {
    setSelectedRate(rate);
    setIsDeleteRateModalOpen(true);
  };

  const handleDeleteRateModalClose = () => {
    setSelectedRate(null);
    setIsDeleteRateModalOpen(false);
  };

  // Модалка редактирования тарифа

  const handleUpdateRateModalOpen = (rate: RateInfo) => {
    setSelectedRate(rate);
    setIsUpdateRateModalOpen(true);
  };

  const handleUpdateRateModalClose = () => {
    setSelectedRate(null);
    setIsUpdateRateModalOpen(false);
  };

  // Фильтры

  const fetchFilters = async () => {
    try {
      const response = await ChargeService.getSessionsFilters();
      if (response.status !== 401) {
        setRateFiltersResponseData(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      // console.error('Ошибка при загрузке фильтров:', error);
    }
  };

  const handleTextChange = debounce((text: string, key: string) => {
    setTextValues((prevTextValues) => ({
      ...prevTextValues,
      [key]: text,
    }));
  }, 1500);

  const handleOptionSelect = debounce((selected: string[], filterKey: string) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [filterKey]: selected,
    }));
  }, 500);

  const handleBooleanChange = debounce((value: boolean, key: string) => {
    if (booleanValues[key] !== value) {
      setBooleanValues((prevBooleanValues) => ({
        ...prevBooleanValues,
        [key]: value,
      }));
    }
  }, 500);

  const handleClearClick = debounce((filterKey: string) => {
    setTextValues((prevValues) => ({ ...prevValues, [filterKey]: '' }));
  }, 1500);

  const handleBooleanClear = debounce((filterKey: string) => {
    if (booleanValues[filterKey] !== undefined) {
      setBooleanValues((prevValues) => ({
        ...prevValues,
        [filterKey]: undefined,
      }));
    }
  }, 500);

  // Получение тарифов

  const fetchRates = async () => {
    setLoading(true);
    try {
      const newFilters: any = [];
      const uniqueFilters = new Set();

      for (const filter of rateFiltersResponseData) {
        if (
          (filter.type === 'MULTISELECT' && selectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'TEXT' && textValues[filter.key]) ||
          (filter.type === 'BOOLEAN' && booleanValues[filter.key] !== undefined)
        ) {
          const filterKey = filter.key;

          if (!uniqueFilters.has(filterKey)) {
            uniqueFilters.add(filterKey);

            let values;
            if (filter.type === 'MULTISELECT') {
              values = selectedOptions[filter.key];
            } else if (filter.type === 'TEXT') {
              values = [textValues[filter.key]];
            } else if (filter.type === 'BOOLEAN') {
              values = [booleanValues[filter.key]];
            }

            newFilters.push({
              key: filter.key,
              values,
            });
          }
        }
      }
      setFilters(newFilters.length > 0);

      const responseFilter = await ChargeService.getRates(newFilters);
      if (responseFilter.status !== 401) {
        setRates(responseFilter.data);
      }
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchRates = async () => {
  //   try {
  //     const response = await ChargeService.getRates([]);
  //     setRates(response.data.data.data);
  //   } catch {
  //     // console.log('error');
  //   }
  // };

  const handleReset = () => {
    store.setShouldWipeFilters(true);
    setTextValues({});
    setSelectedOptions({});
    setBooleanValues({});
  };

  useEffect(() => {
    fetchRates();
    fetchFilters();
  }, [isDeleteRateModalOpen, isAddRateModalOpen, isUpdateRateModalOpen]);

  const areAllSelectedOptionsEmpty = Object.values(selectedOptions).every((array) => array.length === 0);
  const isAnyTextValueNotEmpty = Object.values(textValues).some((value) => value.trim() !== '');
  const isAnyBooleanValueNotEmpty = Object.values(booleanValues).some((value) => value !== undefined && value !== null);

  const shouldShowResetFilters =
  !areAllSelectedOptionsEmpty ||
  isAnyTextValueNotEmpty ||
  isAnyBooleanValueNotEmpty;

  useEffect(() => {
    if (
      (Object.keys(selectedOptions).length > 0 && areAllSelectedOptionsEmpty) ||
      Object.keys(selectedOptions).length > 0 ||
      (Object.keys(textValues).length > 0 && isAnyTextValueNotEmpty) ||
      Object.keys(textValues).length > 0 ||
      (isAnyBooleanValueNotEmpty && Object.keys(booleanValues).length > 0) ||
      Object.keys(booleanValues).length > 0
    ) {
      fetchRates();
    }
    store.setShouldWipeFilters(false);
  }, [selectedOptions, textValues, booleanValues]);

  return (
    <>
    <div className='ratePage-container'>
      <div className='ratePage-header'>
        <div className='ratePage-header-title'>
          <h2>Тарифы</h2>
        </div>
        <div className='ratePage-header-buttons'>
          <LargeButtonWithIcon
            text={'Создать тариф'}
            color={'--MyWhite'}
            LeftIcon={plus}
            iconMarginRight='8px'
            onClick={handleAddRateModalOpen}
            backgroundColor={'--MyPrimary'}
            padding='8px 12px'/>
          {isAddRateModalOpen &&
            <AddRateDetailedModal
              onClose={handleAddRateModalClose}
              isModalOpen={isAddRateModalOpen}
            />
          }
        </div>
      </div>
      <div style={{ marginBottom: '24px' }}></div>
      <div className="carsPage-filters-buttons">
        {(rates?.total !== 0 || filters) && rateFiltersResponseData && rateFiltersResponseData.length > 0 && (
          <>
            {rateFiltersResponseData.map((filter, index) => (
              <div key={index}>
                {filter.type === 'MULTISELECT' && (
                  <Select
                    label={filter.name || filter.key}
                    options={filter.options}
                    selectedOptions={selectedOptions[filter.key] || []}
                    onOptionSelect={(selected) => handleOptionSelect(selected, filter.key)}
                    type={filter.type}
                    filterKey={filter.key}/>
                )}
                {filter.type === 'TEXT' && (
                  <Select
                    label={filter.name || filter.key}
                    options={[]}
                    selectedOptions={[]}
                    onClearClick={() => handleClearClick(filter.key)}
                    onOptionSelect={(value: string) => handleTextChange(value, filter.key)}
                    type={filter.type}
                    filterKey={filter.key}/>
                )}
                {filter.type === 'BOOLEAN' && (
                  <Select
                    label={filter.name || filter.key}
                    options={[]}
                    onClearClick={() => handleBooleanClear(filter.key)}
                    onOptionSelect={(value: boolean) => handleBooleanChange(value, filter.key)}
                    type={filter.type}
                    filterKey={filter.key} />
                )}
              </div>
            ))}
          </>
        )}
        {shouldShowResetFilters && (
          <LargeButtonWithIcon
            onClick={handleReset}
            text={'Сбросить фильтры'}
            color={'--MyPrimary'}
            backgroundColor={'--MySecondaryBlue'}
            padding='7px 12px'
          />
        )}
      </div>
      <div style={{ marginBottom: '24px' }}></div>
      {(rates && rates?.total !== 0) && <div className='ratePage-connectors-list'>
        {rates?.rows.map((rate: RateInfo) => (
          <div key={rate.id} className='ratePage-connector-container'>
            <div className='ratePage-connector-rate-container'>
              <div className='ratePage-connector-rate-title'>
                <span className='ratePage-connector-rate-title-name'>{rate.title ? rate.title : 'Тариф Без названия'}</span>
                <div className='ratePage-connector-rate-title-buttons'>
                  <span onClick={() => handleUpdateRateModalOpen(rate)}>{edit}</span>
                  {isUpdateRateModalOpen && selectedRate && selectedRate.id === rate.id &&
                  <UpdateRateDetailedModal
                    rateId={rate.id}
                    onClose={handleUpdateRateModalClose}
                    isModalOpen={isUpdateRateModalOpen}
                    title={rate.title}
                    periods={rate.elements}/>
                  }
                  <span onClick={() => handleDeleteRateModalOpen(rate)}>{bucket}</span>
                  {isDeleteRateModalOpen && selectedRate && selectedRate.id === rate.id &&
                    <DeleteRateModal
                      rateTitle={rate.title}
                      onClose={handleDeleteRateModalClose}
                      isModalOpen={isDeleteRateModalOpen}
                      title={'Удаление тарифа'}
                      description={'Вы уверены, что хотите удалить тариф'}
                      rateId={rate.id}
                      accessOption={'Да'}
                    />
                  }
                </div>
              </div>
              <div className='ratePage-connector-rate-separator'></div>
              <div className='ratePage-connector-rate-description'>
                {/* <div className='ratePage-connector-rate-title'>
                  <span className='ratePage-connector-rate-name'>Организация</span>
                  <span>{rate.party_id}</span>
                </div>
                <div className='ratePage-connector-rate-title'>
                  <span className='ratePage-connector-rate-name'>Старт зарядки</span>
                  <span>0 ₽</span>
                </div> */}
                {rate.elements[0] && rate.elements[0].restrictions !== undefined && rate.elements[0].price_components !== undefined && rate.elements.map((period: RateElement, index) => (
                <div key={index} className='ratePage-connector-rate-title'>
                  <span className='ratePage-connector-rate-name'>Цена с {period.restrictions.start_time} до {period.restrictions.end_time}</span>
                  <span>{period.price_components[0].price} ₽/кВт•ч</span>
                </div>
                ))}
                {/* <div className='ratePage-connector-rate-title'>
                  <span className='ratePage-connector-rate-name'>Коннекторов с этим тарифом </span>
                  <span>{rate.address[0].length}</span>
                </div> */}
              </div>
            </div>
          </div>))
        }
      </div>}
      {rates && rates?.total === 0 && <p className='ratePage-empty-rates'>Нет тарифов</p>}
    </div>
    {/* <App/> */}
  </>
  );
};

export default RatePage;
